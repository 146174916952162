import React, { useEffect } from "react";
import { Button, Divider, Grid, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import ModalCommon from "../../../common/ModalCommon";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import useSellers from "../../../../redux/Seller/selector";

function AddSeller({
  setOpenModal,
  openModal,
  setIsEditable,
  isEditable,
}) {
  const { create, update, seller, status, createStatus, updateStatus, reset } = useSellers()

  const loadingBoolean =
    (createStatus === "pending" ? true : false) ||
    (updateStatus === "pending" ? true : false) ||
    (isEditable && !Object.keys(seller).length ? true : false);

  const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const addSellerSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    companyName: Yup.string().required("Company name is required"),
    email: Yup.string().trim().matches(emailRegExp, "Please enter valid email").required("Please enter your email ID"),
    password: !isEditable ? Yup.string().required("Password is required") : Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      name: isEditable && seller ? seller.name : "",
      companyName: isEditable && seller ? seller.companyName : "",
      email: isEditable && seller ? seller.email : "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: addSellerSchema,
    onSubmit: (values) => {
      handleSubmitData(values);
    },
  });

    useEffect(() => {
      if (updateStatus === "success" || createStatus === "success") {
        handleCloseModal();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateStatus, createStatus]);

  const handleSubmitData = (values) => {
    if (isEditable) {
      const { password, ...restData } = { ...values, sellerId: seller._id };
      update(restData);
    } else {
      create(values);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    formik.resetForm();
    setIsEditable(false);
    reset();
  };

  return (
    <ModalCommon
      open={openModal}
      handleClose={handleCloseModal}
      title={isEditable ? "Edit Seller" : "Add Seller"}
    >
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                name="name"
                type="text"
                fullWidth
                disabled={loadingBoolean}
                onChange={formik.handleChange}
                value={formik.values.name}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={
                  formik.errors.name && formik.touched.name
                    ? formik.errors.name
                    : null
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="outlined-basic"
                label="Company name"
                variant="outlined"
                name="companyName"
                type="text"
                disabled={loadingBoolean}
                onChange={formik.handleChange}
                value={formik.values.companyName}
                fullWidth
                onBlur={formik.handleBlur}
                error={
                  formik.touched.companyName &&
                  Boolean(formik.errors.companyName)
                }
                helperText={
                  formik.errors.companyName && formik.touched.companyName
                    ? formik.errors.companyName
                    : null
                }
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                name="email"
                type="email"
                disabled={loadingBoolean}
                onChange={formik.handleChange}
                value={formik.values.email}
                fullWidth
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={
                  formik.errors.email && formik.touched.email
                    ? formik.errors.email
                    : null
                }
              />
            </Grid>
            {!isEditable && (
              <Grid item md={6} xs={12}>
                <TextField
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  name="password"
                  type="text"
                  disabled={loadingBoolean}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  fullWidth
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={
                    formik.errors.password && formik.touched.password
                      ? formik.errors.password
                      : null
                  }
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Stack mt={2}>
                <Divider />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <DialogActions>
                <LoadingButton
                  type="submit"
                  loading={
                    createStatus === "pending" ||
                    updateStatus === "pending" ||
                    status === "pending"
                      ? true
                      : false
                  }
                  variant="contained"
                >
                  Save
                </LoadingButton>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </ModalCommon>
  );
}

export default AddSeller;
