import React, { useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import CategoryIcon from "@mui/icons-material/Category";
import WidgetsIcon from "@mui/icons-material/Widgets";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import InventoryIcon from "@mui/icons-material/Inventory";
import SettingsIcon from "@mui/icons-material/Settings";
import ListItemIcon from "@mui/material/ListItemIcon";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  setSidebarIcon: {
    color: "#c2c7d0",
  },
  selectedIndex: {
    "& .Mui-selected": {
      backgroundColor: "rgb(43 123 203 / 15%) !important",
      borderRadius: "0 25px 25px 0",
      borderLeft: "4px solid #ffffff",
      "&:hover": {
        backgroundColor: "rgb(43 123 203 / 15%) !important",
      },
    },
  },
  effectList: {
    marginBottom: "20px",
    "&:hover": {
      backgroundColor: "rgba(43 123 203 / 15%) !important",
      borderRadius: "0 25px 25px 0",
    },
  },
}));

export default function Sidebardata() {
  const [selectedIndex, setselectedIndex] = useState(0);
  const location = useLocation();

  const handleMasterList = () => {
    setselectedIndex(0);
  };

  const classes = useStyles(selectedIndex);
  return (
    <>
      <List className={classes.selectedIndex}>
        {/* Users */}
        <ListItemButton
          button
          component={Link}
          to="/users"
          selected={"/users" === location.pathname || "/" === location.pathname}
          onClick={handleMasterList}
          className={classes.effectList}
        >
          <ListItemIcon style={{ minWidth: "45px" }}>
            <PeopleAltIcon className={classes.setSidebarIcon} />
          </ListItemIcon>
          <ListItemText primary="Users" className={classes.setSidebarIcon} />
        </ListItemButton>
        {/* Seller */}
        <ListItemButton
          button
          component={Link}
          to="/seller"
          selected={"/seller" === location.pathname}
          onClick={handleMasterList}
          className={classes.effectList}
        >
          <ListItemIcon style={{ minWidth: "45px" }}>
            <PersonIcon className={classes.setSidebarIcon} />
          </ListItemIcon>
          <ListItemText primary="Seller" className={classes.setSidebarIcon} />
        </ListItemButton>
        {/* Category */}
        <ListItemButton
          button
          component={Link}
          to="/categories"
          selected={"/categories" === location.pathname}
          onClick={handleMasterList}
          className={classes.effectList}
        >
          <ListItemIcon style={{ minWidth: "45px" }}>
            <CategoryIcon className={classes.setSidebarIcon} />
          </ListItemIcon>
          <ListItemText
            primary="Categories"
            className={classes.setSidebarIcon}
          />
        </ListItemButton>
        {/* Product */}
        <ListItemButton
          button
          component={Link}
          to="/products"
          selected={"/products" === location.pathname}
          onClick={handleMasterList}
          className={classes.effectList}
        >
          <ListItemIcon style={{ minWidth: "45px" }}>
            <WidgetsIcon className={classes.setSidebarIcon} />
          </ListItemIcon>
          <ListItemText primary="Products" className={classes.setSidebarIcon} />
        </ListItemButton>
        {/* Returned Products */}
        <ListItemButton
          button
          component={Link}
          to="/returned-orders"
          selected={"/returned-orders" === location.pathname}
          onClick={handleMasterList}
          className={classes.effectList}
        >
          <ListItemIcon style={{ minWidth: "45px" }}>
            <InventoryIcon className={classes.setSidebarIcon} />
          </ListItemIcon>
          <ListItemText
            primary="Returned Orders"
            className={classes.setSidebarIcon}
          />
        </ListItemButton>
        {/* Orders */}
        <ListItemButton
          button
          component={Link}
          to="/orders"
          selected={"/orders" === location.pathname}
          onClick={handleMasterList}
          className={classes.effectList}
        >
          <ListItemIcon style={{ minWidth: "45px" }}>
            <InventoryIcon className={classes.setSidebarIcon} />
          </ListItemIcon>
          <ListItemText primary="Orders" className={classes.setSidebarIcon} />
        </ListItemButton>
        {/* Settings */}
        <ListItemButton
          button
          component={Link}
          to="/settings"
          selected={"/settings" === location.pathname}
          onClick={handleMasterList}
          className={classes.effectList}
        >
          <ListItemIcon style={{ minWidth: "45px" }}>
            <SettingsIcon className={classes.setSidebarIcon} />
          </ListItemIcon>
          <ListItemText primary="Settings" className={classes.setSidebarIcon} />
        </ListItemButton>
      </List>
    </>
  );
}
