import { LoadingButton } from '@mui/lab'
import { Avatar, Button, DialogActions, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ModalCommon from '../../../../common/ModalCommon'
import useVariant from '../../../../../redux/ProductVariants/selector';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from '@mui/icons-material/Remove';
import { useFormik } from 'formik';
import * as Yup from "yup";

const AddVariant = ({ openModal, setOpenModal, product }) => {
    const { createStatus, createVariant, resetVariant } = useVariant();
    const productClone = product[0]

    const loadingBoolean = (createStatus === "pending" ? true : false) || (!Object.keys(product).length ? true : false);

    const [optionArray, setOptionArray] = useState([])
    const [allVariants, setAllVariants] = useState({ code: "", price: "", qualityClass: "" })

    let variantSchemaObj = {}
    if (productClone?.optionName1 && productClone?.optionName1 !== "") {
        variantSchemaObj.optionName1 = Yup.string().required("Option name is required")
    }
    if (productClone?.optionName2 && productClone?.optionName2 !== "") {
        variantSchemaObj.optionName2 = Yup.string().required("Option name is required")
    }
    if (productClone?.optionName3 && productClone?.optionName3 !== "") {
        variantSchemaObj.optionName3 = Yup.string().required("Option name is required")
    }
    if (productClone?.optionName4 && productClone?.optionName4 !== "") {
        variantSchemaObj.optionName4 = Yup.string().required("Option name is required")
    }

    const addVariantSchema = Yup.object().shape({
        variants: Yup.array().of(
            Yup.object().shape({
                ...variantSchemaObj,
                price: Yup.string().required("Price is required"),
                code: Yup.string().required("Code is required"),
                qualityClass: Yup.string().required("Quality class is required"),
            })
        )
    });

    const formik = useFormik({
        initialValues: {
            productId: product[0]?._id ?? "",
            variants: [{ code: "", price: "", qualityClass: "" }]
        },
        enableReinitialize: true,
        validationSchema: addVariantSchema,
        onSubmit: (values) => {
            createVariant(values)
        }
    })

    const handleVariantChange = (e, index) => {
        const variantClone = [...formik.values.variants]
        const objClone = { ...variantClone[index] }
        objClone[e.target.name] = e.target.value
        variantClone[index] = objClone
        formik.setFieldValue("variants", variantClone)
    }

    const handleAddRow = () => {
        const newRow = [...formik.values.variants, allVariants]
        formik.setFieldValue("variants", newRow)
    }

    const handleRemoveRow = (index) => {
        const deletedRow = formik.values.variants.filter((item, i) => i !== index)
        formik.setFieldValue("variants", deletedRow)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        setAllVariants([{ code: "", price: "", qualityClass: "" }])
        formik.setFieldValue("variants", [{ code: "", price: "", qualityClass: "" }])
        formik.resetForm()
        resetVariant()
    }

    useEffect(() => {
        if (createStatus === "success") {
            handleCloseModal()
        }
    }, [createStatus])

    useEffect(() => {
        if (product?.length) {
            let array = []
            Object.keys(productClone).filter((item, index) => {
                if (item.includes("optionName")) {
                    array.push(productClone[`${item}`])
                }
            })
            setOptionArray(array)
        }
    }, [product, productClone])


    useEffect(() => {
        if (openModal) {
            let newRow = {};
            optionArray.forEach((item, index) => {
                if (item !== "") {
                    newRow[`optionName${index + 1}`] = ""
                }
            })
            newRow = {
                ...newRow,
                code: "", price: "", qualityClass: ""
            }
            setAllVariants(newRow)
            formik.setFieldValue("variants", [newRow])
        }
    }, [openModal])

    return (
        <ModalCommon
            open={openModal}
            handleClose={handleCloseModal}
            title="Add Variant"
            mxWidth="xl"
        >
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {optionArray?.map((item) => {
                                                return item !== "" ? <TableCell>{item}</TableCell> : null
                                            })}

                                            <TableCell>Code</TableCell>
                                            <TableCell>Price</TableCell>
                                            <TableCell>Quality Class</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {formik.values.variants.map((mainItem, mainIndex) => {
                                            return <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                {mainItem?.hasOwnProperty("optionName1") ? <TableCell sx={{ minWidth: "180px" }}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={optionArray[0]}
                                                        variant="outlined"
                                                        name="optionName1"
                                                        type="text"
                                                        disabled={loadingBoolean}
                                                        fullWidth
                                                        onChange={(e) => handleVariantChange(e, mainIndex)}
                                                        value={mainItem?.optionName1}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched?.variants?.length && Boolean(formik.errors?.variants?.[mainIndex]?.optionName1)}
                                                        helperText={formik.touched?.variants?.length && formik.errors?.variants?.[mainIndex]?.optionName1}
                                                    />
                                                </TableCell> : null}
                                                {mainItem?.hasOwnProperty("optionName2") ? <TableCell sx={{ minWidth: "180px" }}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={optionArray[1]}
                                                        variant="outlined"
                                                        name="optionName2"
                                                        type="text"
                                                        fullWidth
                                                        disabled={loadingBoolean}
                                                        onChange={(e) => handleVariantChange(e, mainIndex)}
                                                        value={mainItem?.optionName2}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched?.variants?.length && Boolean(formik.errors?.variants?.[mainIndex]?.optionName2)}
                                                        helperText={formik.touched?.variants?.length && formik.errors?.variants?.[mainIndex]?.optionName2}
                                                    />
                                                </TableCell> : null}
                                                {mainItem?.hasOwnProperty("optionName3") ? <TableCell sx={{ minWidth: "180px" }}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={optionArray[2]}
                                                        variant="outlined"
                                                        name="optionName3"
                                                        type="text"
                                                        fullWidth
                                                        disabled={loadingBoolean}
                                                        onChange={(e) => handleVariantChange(e, mainIndex)}
                                                        value={mainItem?.optionName3}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched?.variants?.length && Boolean(formik.errors?.variants?.[mainIndex]?.optionName3)}
                                                        helperText={formik.touched?.variants?.length && formik.errors?.variants?.[mainIndex]?.optionName3}
                                                    />
                                                </TableCell> : null}
                                                {mainItem?.hasOwnProperty("optionName4") ? <TableCell sx={{ minWidth: "180px" }}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={optionArray[3]}
                                                        variant="outlined"
                                                        name="optionName4"
                                                        type="text"
                                                        disabled={loadingBoolean}
                                                        fullWidth
                                                        onChange={(e) => handleVariantChange(e, mainIndex)}
                                                        value={mainItem?.optionName4}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched?.variants?.length && Boolean(formik.errors?.variants?.[mainIndex]?.optionName4)}
                                                        helperText={formik.touched?.variants?.length && formik.errors?.variants?.[mainIndex]?.optionName4}
                                                    />
                                                </TableCell> : null}

                                                <TableCell sx={{ minWidth: "180px" }}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="Code"
                                                        variant="outlined"
                                                        name="code"
                                                        value={mainItem?.code}
                                                        fullWidth
                                                        disabled={loadingBoolean}
                                                        onChange={(e) => handleVariantChange(e, mainIndex)}
                                                        error={formik.touched?.variants?.length && Boolean(formik.errors?.variants?.[mainIndex]?.code)}
                                                        helperText={formik.touched?.variants?.length && formik.errors?.variants?.[mainIndex]?.code}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ minWidth: "180px" }}>
                                                    <TextField
                                                        fullWidth
                                                        id="outlined-basic"
                                                        label="Price"
                                                        variant="outlined"
                                                        name="price"
                                                        disabled={loadingBoolean}
                                                        value={mainItem?.price}
                                                        onChange={(e) => handleVariantChange(e, mainIndex)}
                                                        error={formik.touched?.variants?.length && Boolean(formik.errors?.variants?.[mainIndex]?.price)}
                                                        helperText={formik.touched?.variants?.length && formik.errors?.variants?.[mainIndex]?.price}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ minWidth: "180px" }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Quality Class</InputLabel>
                                                        <Select
                                                            defaultValue=""
                                                            name="qualityClass"
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            label="Quality Class"
                                                            disabled={loadingBoolean}
                                                            value={mainItem?.qualityClass}
                                                            onChange={(e) => handleVariantChange(e, mainIndex)}
                                                            error={formik.touched?.variants?.[mainIndex]?.qualityClass && Boolean(formik.errors?.variants?.[mainIndex]?.qualityClass)}
                                                            helperText={formik.touched?.variants?.[mainIndex]?.qualityClass && Boolean(formik.errors?.variants?.[mainIndex]?.qualityClass)}
                                                        >
                                                            <MenuItem value="A">A</MenuItem>
                                                            <MenuItem value="B">B</MenuItem>
                                                            <MenuItem value="C">C</MenuItem>
                                                        </Select>
                                                        <Stack color={"#d32f2f"} fontSize="12px" ml={1.8} mt={0.5}>
                                                            {formik.touched?.variants?.[mainIndex]?.qualityClass && Boolean(formik.errors?.variants?.[mainIndex]?.qualityClass)
                                                                ? formik.errors.variants?.[mainIndex]?.qualityClass
                                                                : null}
                                                        </Stack>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {mainIndex === 0 ?
                                                        <Tooltip title="Add Variant" >
                                                            <Avatar
                                                                sx={{
                                                                    background: "#4267B2",
                                                                    width: 35,
                                                                    height: 35,
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <Button sx={{ color: "#fff", width: "100%", height: "100%" }} onClick={handleAddRow}>
                                                                    <AddIcon fontSize="medium" />
                                                                </Button>
                                                            </Avatar>
                                                        </Tooltip> :
                                                        <Tooltip title="Delete Option">
                                                            <Avatar
                                                                sx={{
                                                                    background: "#ba000d",
                                                                    width: 35,
                                                                    height: 35,
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <Button sx={{ color: "#fff", width: "100%", height: "100%" }} onClick={() => handleRemoveRow(mainIndex)}>
                                                                    <RemoveIcon fontSize="medium" />
                                                                </Button>
                                                            </Avatar>
                                                        </Tooltip>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <DialogActions>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    loading={createStatus === "pending" ? true : false}
                                >
                                    Save
                                </LoadingButton>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    onClick={handleCloseModal}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </ModalCommon>

    )
}

export default AddVariant