import axios from "axios";

export const BaseURL = process.env.REACT_APP_API_URL;

const defaultHeaders = {
  isAuth: true,
  AdditionalParams: {},
  isJsonRequest: true,
  api_key: true,
};

export const ApiPostNoAuth = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        BaseURL + type,
        userData,
        getHttpOptions({ ...defaultHeaders, isAuth: false })
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error?.response?.data?.error) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiPutNoAuth = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        BaseURL + type,
        userData,
        getHttpOptions({ ...defaultHeaders, isAuth: false })
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error?.response?.data?.error) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiGetNoAuth = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BaseURL + type, getHttpOptions({ ...defaultHeaders, isAuth: false }))
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error?.response?.data?.error) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const Api = (type, methodtype, userData) => {
  return new Promise((resolve, reject) => {
    userData = userData || {};
    axios({
      url: BaseURL + type,
      headers: getHttpOptions(),
      data: userData,
      type: methodtype,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        if (error?.response?.data?.error) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiGet = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BaseURL + type, getHttpOptions())
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        if (error?.response?.data?.error) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiPost = (type, userData, AdditionalHeader) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BaseURL + type, userData, {
        ...getHttpOptions({...defaultHeaders, ...AdditionalHeader})
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        if (error?.response?.data?.error) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiPut = (type, userData,AdditionalHeader) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BaseURL + type, userData,getHttpOptions({...defaultHeaders, ...AdditionalHeader}))
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        if (error?.response?.data?.error) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiPatch = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(BaseURL + type, userData, getHttpOptions())
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        if (error?.response?.data?.error) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiDelete = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(BaseURL + type, getHttpOptions())
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.reload();
        }
        if (error?.response?.data?.error) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiDownload = (type, userData) => {
  let method = userData && Object.keys(userData).length > 0 ? "POST" : "GET";
  return new Promise((resolve, reject) => {
    axios({
      url: BaseURL + type,
      method,
      headers: getHttpOptions().headers,
      responseType: "blob",
      data: userData,
    })
      .then((res) => resolve(new Blob([res.data])))
      .catch((error) => {
        if (error?.response?.data?.error) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiGetBuffer = (url) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET",
      mode: "no-cors",
    })
      .then((response) => {
        if (response.ok) {
          return response.buffer();
        } else {
          resolve(null);
        }
      })
      .then((buffer) => {
        resolve(buffer);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getHttpOptions = (options = defaultHeaders) => {
  let headers = {};
  if (options?.isAuth) {
    if (localStorage.getItem("token")) {
      headers["Authorization"] = "Bearer " + localStorage.getItem("token");
    }
  }
  if (options?.api_key) {
    headers["api_key"] = "6QSy49rUTH";
  }
  if (options?.isJsonRequest && !options?.isFormData) {
    headers["Content-Type"] = "application/json";
  }
  if (options?.isFormData) {
    headers["Content-Type"] = "multipart/form-data";
  }
  if (options?.AdditionalParams) {
    headers = { ...headers, ...options.AdditionalParams };
  }

  return { headers };
};
