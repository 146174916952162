import { makeStyles } from '@material-ui/core'
import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import useOrders from '../../../../redux/Orders/selector'
import DataTable from '../../../common/DataTable'
import ModalCommon from '../../../common/ModalCommon'

const useStyleAuth = makeStyles((theme) => ({
    error: {
        color: 'red'
    }
}))

const ConfirmOrder = ({ openModal, handleCloseModal, order }) => {
    const classes = useStyleAuth();
    const [rows, setRows] = useState([])
    const { orderConfirm, confirmStatus } = useOrders();
    const [error, setError] = useState("")

    const columns = [
        { id: "name", label: "Product Name" },
        { id: "quantity", label: "Quantity" },
        { id: "qualityClass", label: "Quality Class" },
    ];

    const handleSubRowChange = (rowIndex, subRowIndex) => {
        setError("")
        const rowsClone = [...rows]
        const selectedSubRows = rowsClone[rowIndex]['subRows'].filter((item) => item.checked).length
        if (selectedSubRows < rowsClone[rowIndex]['quantity']) {
            rowsClone[rowIndex]['subRows'][subRowIndex]['checked'] = !rowsClone[rowIndex]['subRows'][subRowIndex]['checked']
        } else {
            rowsClone[rowIndex]['subRows'][subRowIndex]['checked'] = false
        }
        setRows(rowsClone)
    }

    const handleSubmit = async () => {
        const payload = {
            orderId: order._id,
            orderItems: []
        }
        if (rows.length > 0) {
            rows.map((item) => {
                const productVariantId = item.id
                let itemDetails = []
                item?.subRows?.forEach((ele) => {
                    if (ele?.checked) {
                        itemDetails.push({
                            IMEI: ele.IMEI,
                            seller: ele.seller_id,
                        })
                    }
                })
                payload['orderItems'].push({ productVariantId, itemDetails, quantity: item.quantity })
            })
            const index = payload['orderItems'].findIndex((item) => item.itemDetails.length !== item.quantity)
            if (index >= 0) {
                setError("Please select the IMEI")
            } else {
                await orderConfirm(payload)
                handleCloseModal()
                setError("")
            }
        }
    }

    const getSubRows = (Array) => {
        const subRows = Array.map((item) => {
            return {
                ...item,
                seller: item.seller.name,
                seller_id: item.seller._id,
                checked: false,
            };
        });
        return subRows;
    };

    useEffect(() => {
        if (order) {
            let data = []
            order?.orderItems?.map((item) => {
                data.push({
                    id: item?.productVariantId.id,
                    name: item?.productVariantId.productId?.name,
                    quantity: item?.quantity,
                    subRows:
                        order.status === "Pending"
                            ? getSubRows(item.availableQty)
                            : getSubRows(item.itemDetails),
                    subColums: ["seller", "IMEI"],
                    qualityClass: item.productVariantId?.qualityClass,
                    search:true
                })
            });
            setRows(data)
        }
    }, [order])

    useEffect(() => {
        if (confirmStatus === "success") {
            handleCloseModal();
            setError("");
        }
    }, [confirmStatus]);

    return (
        <ModalCommon
            open={openModal}
            handleClose={() => {
                handleCloseModal()
                setError("")
            }}
            title="Confirm Order"
            mxWidth="md"
        >
            <DialogContent>
                <Grid container spacing={2}>
                    {error && <Grid item xs={12} className={classes.error}>
                        <Typography>{error}</Typography>
                    </Grid>}
                    <Grid item xs={12}>
                        <DataTable
                            columns={columns}
                            rows={rows}
                            collapsible={true}
                            handleSubRowChange={handleSubRowChange}
                            isStickyHeader={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DialogActions>
                            <LoadingButton type="submit" variant="contained" loading={confirmStatus === "pending"} onClick={handleSubmit}>
                                Confirm
                            </LoadingButton>
                            <Button
                                variant="outlined"
                                size="medium"
                                onClick={() => {
                                    handleCloseModal()
                                    setError("")
                                }}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Grid>
                </Grid>
            </DialogContent>
        </ModalCommon>
    )
}

export default ConfirmOrder
