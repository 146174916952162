import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiPostNoAuth } from "../../component/api";

export const fetchLogin = createAsyncThunk("login/login", async (data, thunkAPI) => {
  try {
    const result = await ApiPostNoAuth("admin/login", data);
    if (result.getToken) {
      localStorage.setItem("token", result.getToken);
      return thunkAPI.fulfillWithValue(result.getToken);
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return thunkAPI.rejectWithValue();
  }
});

export const fetchLogout = createAsyncThunk("login/logout", async (thunkAPI) => {
  try {
    localStorage.clear();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    status: "idle",
    token: "",
  },
  reducers: {
    resetLogin(state) {
      state.status = "idle";
    },
  },
  extraReducers: {
    [fetchLogin.pending]: (state) => {
      state.status = "pending";
    },
    [fetchLogin.fulfilled]: (state, action) => {
      state.status = "success";
      state.token = action.payload;
    },
    [fetchLogin.rejected]: (state) => {
      state.status = "failed";
    },
    [fetchLogout.pending]: (state) => {
      state.status = "idle";
    },
    [fetchLogout.fulfilled]: (state) => {
      state.status = "success";
      state.token = "";
    },
    [fetchLogout.rejected]: (state) => {
      state.status = "faild";
    },
  },
});
export const { resetLogin } = loginSlice.actions;

export default loginSlice.reducer;
