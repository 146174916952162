import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { Paper, Grid, makeStyles } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import useAuth from "../../redux/Login/selector";

const useStyleAuth = makeStyles((theme) => ({
  setloginbackpaper: {
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
    display: "flex !important",
    justifyContent: "center",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "510px",
    borderRadius: "10px",
  },
  setsignuilink: {
    textDecoration: "none",
    cursor: "pointer",
    fontWeight: "600 !important",
    marginLeft: "5px !important",
    color: "#7f2121 !important",
  },
  setbottomtypography: {
    display: "flex",
    padding: "24px 0 0",
    justifyContent: "center",
  },
  setloginbutton: {
    marginTop: "25px !important",
    backgroundColor: "#367fa9 !important",
    height: "50px",
  },
  setacctypography: {
    fontSize: "14px !important",
    lineHeight: "21px !important",
    color: "#4c4747",
  },
  setcontainerLogin: {
    display: "flex !important",
    maxWidth: "100% !important",
    height: "100vh",
    position: "relative",
    zIndex: 1,
    backgroundColor: "aliceblue",
  },
}));

export default function Login() {
  const { loginStatus, loginToken, login } = useAuth();

  const [passvisible, setPassvisible] = useState(false);
  const classes = useStyleAuth();
  const history = useHistory();

  const handleClickShowPassword = () => {
    setPassvisible(!passvisible);
  };

  const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      email: Yup.string("Enter your email")
        .email().trim().matches(emailRegExp, "Please enter valid email").required("Email is required"),
      password: Yup.string("Enter your password")
        .min(8, "Password should be of minimum 8 characters length")
        .required("Password is required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    if (values.email && values.password) {
      const data = {
        email: values.email,
        password: values.password,
      };
      login(data);
    }
  };

  useEffect(() => {
    if (loginStatus === "success" && loginToken) {
      history.push("/");
    }
  }, [loginStatus, loginToken, history]);


  const otherProps = {
    InputProps: {
      endAdornment: (
        <InputAdornment position="start">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => handleClickShowPassword()}
          >
            {passvisible ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      ),
    },
  };

  return (
    <>
      {!loginToken && <Container
        component="main"
        maxWidth="xl"
        className={classes.setcontainerLogin}
      >
        <Paper className={classes.setloginbackpaper} elevation={5}>
          <Typography variant="h4" gutterBottom sx={{ color: "#3c8dbc" }}>
            Admin
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  name="email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  fullWidth
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={
                    formik.errors.email && formik.touched.email
                      ? formik.errors.email
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  type={passvisible ? "text" : "password"}
                  value={formik.values.password}
                  label="Password"
                  onChange={formik.handleChange}
                  name="password"
                  onBlur={formik.handleBlur}
                  helperText={
                    formik.errors.password && formik.touched.password
                      ? formik.errors.password
                      : null
                  }
                  fullWidth
                  error={formik.errors.password && formik.touched.password}
                  {...otherProps}
                />
              </Grid>
            </Grid>
            <Button
              id="loginBtn"
              variant="contained"
              className={classes.setloginbutton}
              type="submit"
              fullWidth
              disabled={loginStatus === "pending"}
            >
              {loginStatus === "pending" ? "Loading..." : "Login"}
            </Button>
          </form>

          {/* <div className={classes.setbottomtypography}>
            <Typography
              variant="body2"
              className={classes.setacctypography}
              gutterBottom
            >
              Don't have an Account ?
            </Typography>
            <Typography
              className={classes.setsignuilink}
              variant="body2"
              noWrap
              component={Link}
              to="/auth/register"
              color="textPrimary"
              underline="none"
            >
              Sign up.
            </Typography>
          </div> */}
        </Paper>
      </Container>}
    </>
  );
}
