import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiGet, ApiPost } from "../../component/api";

export const fetchProductVariant = createAsyncThunk(
    "productVariant/fetchProductVariant",
    async (id, thunkAPI) => {
        try {
            const result = await ApiGet(`product/variant/${id}`);
            if (result) {
                return thunkAPI.fulfillWithValue(result.data);
            }
        } catch (error) {
            toast.error(error, { autoClose: 3000 });
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const createProductVariant = createAsyncThunk(
    "productVariant/createProductVariant",
    async (data, thunkAPI) => {
        try {
            const result = await ApiPost("product/variant/create", data);
            if (result) {
                toast.success(result.message, { autoClose: 3000 });
                return thunkAPI.fulfillWithValue(result.data);
            }
        } catch (error) {
            toast.error(error, { autoClose: 3000 });
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const updateProductVariant = createAsyncThunk(
    "productVariant/updateProductVariant",
    async (data, thunkAPI) => {
        try {
            const result = await ApiPost("product/variant/update", data);
            if (result) {
                toast.success(result.message, { autoClose: 3000 });
                return thunkAPI.fulfillWithValue(result.data);
            }
        } catch (error) {
            toast.error(error, { autoClose: 3000 });
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const deleteProductVariant = createAsyncThunk(
    "productVariant/deleteProductVariant",
    async (id, thunkAPI) => {
        try {
            const result = await ApiPost("product/variant/delete", id);
            if (result) {
                toast.success(result.message, { autoClose: 3000 });
                return thunkAPI.fulfillWithValue(id);
            }
        } catch (error) {
            toast.error(error, { autoClose: 3000 });
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const addVariantQuantity = createAsyncThunk(
    "productVariant/addVariantQuantity",
    async (data, thunkAPI) => {
        try {
            const result = await ApiPost("product/variant/quantity/add", data);
            if (result) {
                toast.success(result.message, { autoClose: 3000 });
                return thunkAPI.fulfillWithValue(result.data);
            }
        } catch (error) {
            toast.error(error, { autoClose: 3000 });
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const productVariantSlice = createSlice({
    name: "productVariants",
    initialState: {
        status: "idle",
        createStatus: "idle",
        updateStatus: "idle",
        removeStatus: "idle",
        createQtyStatus: "idle",
        productVariantsData: [],
        message: "",
        error: "",
    },
    reducers: {
        reset(state) {
            state.createStatus = "idle"
            state.updateStatus = "idle"
            state.removeStatus = "idle"
            state.createQtyStatus = "idle"
            // state.productVariantsData = []
        },
    },
    extraReducers: {
        [fetchProductVariant.pending]: (state) => {
            state.status = "pending";
        },
        [fetchProductVariant.fulfilled]: (state, action) => {
            state.status = "success";
            state.productVariantsData = action.payload;
            state.error = "";
        },
        [fetchProductVariant.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        },

        [createProductVariant.pending]: (state) => {
            state.createStatus = "pending";
        },
        [createProductVariant.fulfilled]: (state, action) => {
            state.createStatus = "success";
            state.productVariantsData = [...state.productVariantsData, ...action.payload]
        },
        [createProductVariant.rejected]: (state, action) => {
            state.createStatus = "failed";
            state.error = action.payload;
        },

        [updateProductVariant.pending]: (state) => {
            state.updateStatus = "pending";
        },
        [updateProductVariant.fulfilled]: (state, action) => {
            state.updateStatus = "success";
            const findIndex = state.productVariantsData.findIndex((item) => item._id === action.payload._id)
            if (findIndex !== -1) {
                state.productVariantsData[findIndex] = action.payload
            }
        },
        [updateProductVariant.rejected]: (state, action) => {
            state.updateStatus = "failed";
            state.error = action.payload;
        },

        [deleteProductVariant.pending]: (state) => {
            state.removeStatus = "pending";
        },
        [deleteProductVariant.fulfilled]: (state, action) => {
            state.removeStatus = "success";
            state.productVariantsData = state.productVariantsData.filter((item) => item._id !== action.payload.productVariantId)
        },
        [deleteProductVariant.rejected]: (state, action) => {
            state.removeStatus = "failed";
            state.error = action.payload;
        },

        [addVariantQuantity.pending]: (state) => {
            state.createQtyStatus = "pending";
        },
        [addVariantQuantity.fulfilled]: (state, action) => {
            state.createQtyStatus = "success";
            let findIndex = state.productVariantsData.findIndex((item) => item._id === action.payload._id)
            if (findIndex !== -1) {
                state.productVariantsData[findIndex] = action.payload
            }
        },
        [addVariantQuantity.rejected]: (state, action) => {
            state.createQtyStatus = "failed";
            state.error = action.payload;
        },
    },
});

export const { reset } = productVariantSlice.actions;

export default productVariantSlice.reducer;