import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Badge from "@mui/material/Badge";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Divider, Drawer } from "@mui/material";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import MoreIcon from "@mui/icons-material/MoreVert";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import Avatar from "@mui/material/Avatar";
import Sidebardata from "./Sidebardata";
import { useHistory } from "react-router-dom";
import useAuth from "../../redux/Login/selector";

let DrawerOpenWidth = 300;
const useStyles = makeStyles((theme) => ({
  customBadgemail: {
    backgroundColor: "#00a65a",
    color: "white",
  },
  customBadgenoty: {
    backgroundColor: "#f39c12",
    color: "white",
  },
  custombadgeicon: {
    color: "white",
    "&:hover": {
      color: "rgb(31, 38, 31,0.50)",
    },
  },
  custombadgeMobileicon: {
    color: "black",
    "&:hover": {
      color: "rgb(31, 38, 31,0.50)",
    },
  },
  setnotyfication: {
    display: "flex",
  },
  mobilerightmenu: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  hideiconformobile: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  sidebarmobile: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  mobiledrawer: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  listitemeffect: {
    "&:hover": {
      color: "rgb(31, 38, 31,0.50)",
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  setsidebaricon: {
    color: "#b8c7ce",
    "&:hover": {
      color: "white",
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems:'center',
    margin:'15px'
  }
}));

export default function MobileView() {
  const { logout } = useAuth();
  const [notyOpen, setNotyOpen] = useState(null);
  const [mobileMenu, setMobileMenu] = useState(null);
  const mobileMenunBoolean = Boolean(mobileMenu);
  const notyOpenBoolean = Boolean(notyOpen);
  const [mobileSidebaropen, setMobileSidebaropen] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const handleLogout = () => {
    logout();
    history.push('/login')
  }
  const handleNotyficationClose = () => {
    setNotyOpen(null);
  };

  const handleMobiblenenuClose = () => {
    setMobileMenu(null);
  };

  const handleMobileSidebar = () => {
    setMobileSidebaropen(!mobileSidebaropen);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.setAppbarforweb}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleMobileSidebar}
              sx={{
                marginRight: 3,
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />

            {/* below code for mobile */}
            <Box className={classes.mobilerightmenu}>
              <IconButton
                size="large"
                onClick={(e) => setMobileMenu(e.currentTarget)}
                aria-controls={mobileMenunBoolean ? "Open_Menu" : undefined}
                aria-haspopup="true"
                aria-expanded={mobileMenunBoolean ? "true" : undefined}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
            {/* end mobile code */}
          </Toolbar>
        </AppBar>
      </Box>
      <Box
        component="nav"
        sx={{ width: { xs: DrawerOpenWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileSidebaropen}
          onClose={handleMobileSidebar}
          ModalProps={{ keepMounted: true }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: DrawerOpenWidth,
            },
            "& .MuiPaper-root": { backgroundColor: "#222d32" },
          }}
        >
          <div className={classes.drawerHeader}>
            <Avatar
              src={`<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiAvatar-fallback css-13y7ul3" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PersonIcon"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg>`}
            />
            <Typography variant="button" color="common.white">
              &nbsp;&nbsp;Super Admin
            </Typography>
          </div>
          <Sidebardata />
        </Drawer>
      </Box>
      <Menu
        anchorEl={notyOpen}
        id="openNotysetting"
        open={notyOpenBoolean}
        onClose={handleNotyficationClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 300,
            overflow: "hidden",
            filter: "drop-shadow(0px 5px 8px rgba(0,0,0,0.32))",
            // mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            // before : use for arrow of menulist
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 16,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            data update successfully
          </Typography>
        </MenuItem>
      </Menu>
      {/* mobile Menu */}
      <Menu
        anchorEl={mobileMenu}
        id="Open_Menu"
        open={mobileMenunBoolean}
        onClose={handleMobiblenenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "hidden",
            filter: "drop-shadow(0px 5px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiMenu-list": {
              padding: 0,
            },
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 16,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Badge badgeContent={4} classes={{ badge: classes.customBadgemail }}>
            <MailOutlineOutlinedIcon
              className={classes.custombadgeMobileicon}
            />
          </Badge>
          <div style={{ marginLeft: "10px", fontSize: "18px" }}>Mail</div>
        </MenuItem>
        <MenuItem sx={{ padding: 0 }}>
          <IconButton
            size="large"
            id="openNotysetting"
            aria-controls={notyOpenBoolean ? "openNotysetting" : undefined}
            aria-haspopup="true"
            aria-expanded={notyOpenBoolean ? "true" : undefined}
            onClick={(e) => setNotyOpen(e.currentTarget)}
          >
            <Badge
              badgeContent={4}
              classes={{ badge: classes.customBadgenoty }}
            >
              <NotificationsNoneIcon
                className={classes.custombadgeMobileicon}
              />
            </Badge>
            <div style={{ marginLeft: "10px", fontSize: "18px" }}>
              Notification
            </div>
          </IconButton>
        </MenuItem>
        <MenuItem>
          <MailOutlineOutlinedIcon className={classes.custombadgeMobileicon} />
          <div style={{ marginLeft: "10px", fontSize: "18px" }}>Profile</div>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ExitToAppIcon fontSize="small" />
          <div style={{ marginLeft: "10px", fontSize: "18px" }}>Logout</div>
        </MenuItem>
      </Menu>
    </>
  );
}
