import React, { useEffect } from "react";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import ModalCommon from "../../../common/ModalCommon";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import useSettings from "../../../../redux/Settings/selector";
import * as Yup from "yup";
import UploadImageField from "../../../common/UploadImageField";

function AddConfig({
  setOpenModal,
  openModal,
  setIsEditable,
  isEditable,
  singleConfig,
  reset,
  createStatus,
  updateStatus,
}) {
  const { create, update } = useSettings();

  const loadingBoolean =
    (createStatus === "pending" ? true : false) ||
    (updateStatus === "pending" ? true : false) ||
    (isEditable && singleConfig && !Object.keys(singleConfig).length ? true : false);

  const addConfigSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    type: Yup.string().required("Select Type is required"),
    value: Yup.string().when("selectType", {
      is: "string",
      then: Yup.string().required("Value is required"),
    }),
    configImage: Yup.string().when("selectType", {
      is: "image",
      then: Yup.string().required("Image is required"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      name: isEditable ? singleConfig.name : "",
      value: isEditable ? singleConfig.value : "",
      type: isEditable && singleConfig.type ? singleConfig.type : "",
      configImage: isEditable && singleConfig.value ? singleConfig.value : "",
    },
    enableReinitialize: true,
    validationSchema: addConfigSchema,
    onSubmit: (values) => {
      let formData = new FormData();
      formData.set("name", values.name ?? "");
      if (values.type === "image") {
        formData.set("value", values.name ?? "");
      } else {
        formData.set("value", values.value ?? "");
      }
      formData.set("type", values.type ?? "");
      if (values?.configImage) {
        formData.append("configImage", values.configImage ?? "");
      }
      handleSubmit(formData);
    },
  });

  const handleSubmit = (data) => {
    if (formik.errors.configImage) {
      formik.setTouched({ ...formik.touched, configImage: true });
    }
    if (isEditable) {
      update(data);
    } else {
      create(data);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsEditable(false);
    formik.resetForm();
    reset();
  };

  const handleImageUploadChange = (e) => {
    if (e.target.files[0]) {
      formik.setFieldValue("configImage", e.target.files[0]);
    }
  };

  const valueFunction = () => {
    switch (formik.values.type) {
      case "string":
        return (
          <TextField
            id="outlined-basic"
            label="Value"
            variant="outlined"
            name="value"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.value}
            disabled={loadingBoolean}
            fullWidth
            onBlur={formik.handleBlur}
            error={formik.touched.value && Boolean(formik.errors.value)}
            helperText={
              formik.errors.value && formik.touched.value
                ? formik.errors.value
                : null
            }
          />
        );
      case "image":
        return (
          <UploadImageField
            onChange={handleImageUploadChange}
            value={formik.values.configImage}
            name="configImage"
            disabled={loadingBoolean}
            error={
              formik.errors.configImage && formik.touched.configImage
                ? formik.errors.configImage
                : null
            }
            placeholder="Add Image"
          />
        );
      default:
        return "";
    }
  };

  useEffect(() => {
    if (createStatus === "success" || updateStatus === "success") {
      handleCloseModal();
    }
  }, [createStatus, updateStatus]);

  return (
    <ModalCommon
      open={openModal}
      handleClose={handleCloseModal}
      title={isEditable ? "Edit Config" : "Add Config"}
    >
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                name="name"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.name}
                fullWidth
                onBlur={formik.handleBlur}
                disabled={isEditable ? true : false}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={
                  formik.errors.name && formik.touched.name
                    ? formik.errors.name
                    : null
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl
                fullWidth
                focused={isEditable ? true : false}
                disabled={isEditable ? true : false}
              >
                <InputLabel id="demo-simple-select-label">
                  Select Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formik.selectType}
                  name="type"
                  label="Select Type"
                  onChange={formik.handleChange}
                  defaultValue={
                    isEditable && singleConfig.type ? singleConfig.type : ""
                  }
                >
                  <MenuItem value="string">String</MenuItem>
                  <MenuItem value="image">Image</MenuItem>
                  <MenuItem value="checkbox">Checkbox</MenuItem>
                  <MenuItem value="radio">Radio</MenuItem>
                </Select>
                <Stack color={"#d32f2f"} fontSize="12px" ml={1.8} mt={0.5}>
                  {formik.errors.type && formik.touched.type
                    ? formik.errors.type
                    : null}
                </Stack>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {valueFunction()}
            </Grid>

            <Grid item xs={12}>
              <Stack mt={2}>
                <Divider />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <DialogActions>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={
                    createStatus === "pending" || updateStatus === "pending"
                      ? true
                      : false
                  }
                >
                  Save
                </LoadingButton>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </ModalCommon>
  );
}

export default AddConfig;
