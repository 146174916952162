import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import loginReducer from "./Login/loginSlice";
import usersReducer from "./Users/usersSlice";
import sellerReducer from "./Seller/sellerSlice";
import productReducer from "./Product/productSlice";
import orderReducer from "./Orders/ordersSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import categoryReducer from "./Category/categorySlice";
import productVariantReducer from "./ProductVariants/productVariantSlice";
import settingReducer from "./Settings/settingSlice";

const persistConfig = {
  key: "root",
  storage,
  // Only allow persistence for reducer
  whitelist: ["login"],
};

const rootReducer = combineReducers({
  login: loginReducer,
  users: usersReducer,
  seller: sellerReducer,
  categories: categoryReducer,
  product: productReducer,
  orders: orderReducer,
  productVariants: productVariantReducer,
  setting:settingReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
});

export const persistor = persistStore(store);
export default store;