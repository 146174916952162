import React from 'react'

import Dialog from '@mui/material/Dialog';

import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Typography } from '@mui/material';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        padding: "20px 30px !important",
        margin: 0,
    },
    iconButton: {
        position: 'absolute !important',
        right: 30,
        top: 17,
    },
}))

const ModalCommon = ({ title, open, handleClose, children, mxWidth ,isHeader=true }) => {
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            // onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
            maxWidth={mxWidth}
            fullWidth={true}
        >
            {isHeader ? <>
                <DialogTitle id="draggable-dialog-title" className={classes.dialogTitle}>
                <Typography variant='h6'>{title}</Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    className={classes.iconButton}
                    
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            </> :""}
            {children}
        </Dialog>
    )
}

export default ModalCommon