import React, { useEffect, useMemo } from "react";
import {
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import ModalCommon from "../../../common/ModalCommon";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useFormik } from "formik";
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from "yup";
import useCategory from "../../../../redux/Category/selector";

function AddCategory({ setOpenModal, openModal, data, editData, setEditData, setIsEditable, isEditable }) {
    const { createStatus, updateStatus, removeStatus, create, update, reset } = useCategory()

    const loadingBoolean =
    (createStatus === "pending" ? true : false) ||
    (updateStatus === "pending" ? true : false) ||
    (isEditable && !Object.keys(editData).length ? true : false);

    function flat(array) {
        var result = [];
        array?.forEach(function (a) {
            result.push(a);
            if (Array.isArray(a?.subCategories)) {
                result = result.concat(flat(a?.subCategories));
            }
        });
        return result.filter((item) => item !== undefined);
    }

    const subCategorySchema = Yup.object({
        category: !isEditable && Yup.string().required("Category is required"),
        childCategory: Yup.string("Child Category is required"),
        subCategories: Yup.string().required('Category Name is required'),
    })

    const formik = useFormik({
        initialValues: {
            category: "",
            childCategory: "",
            subCategories: isEditable && editData ? editData.name : "",
        },
        validationSchema: subCategorySchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleSubmitData(values)
        }
    })

    const handleSubmitData = async (values) => {
        let slugUrl = flat(data?.data).find(item => item._id === (values.childCategory !== "" && values.childCategory !== "parent" ? values.childCategory : values.category))?.slug
        let payload = {
            slug: slugUrl ? `${slugUrl}/${values.subCategories}` : `/${values.subCategories}`,
            parentCategory: values.childCategory && values.childCategory !== "parent" ? values.childCategory : values.category,
            name: values.subCategories
        }
        if (editData) {
            let editSlug = editData?.slug.split("/").filter((item) => item !== editData.name).join("/")
            update({ categoryId: editData?._id, name: values?.subCategories.trim(), slug: editSlug + `/${values?.subCategories}` })
        } else {
            create(values?.category !== "" && values?.category !== "parent" ? payload : { name: values.subCategories, slug: `/${values.subCategories}` });
        }
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        formik.resetForm();
        setEditData();
        setIsEditable(false);
        reset();
    };

    const childCategory = useMemo(() => {
        let child = [];
        if (formik.values.category && formik.values.category !== "parent") {
            child = data.find((item) => item.value === formik.values.category).children
        }
        if (!child?.length) return;
        return child;
    }, [formik.values.category]);

    useEffect(() => {
        if (createStatus === "success" || updateStatus === "success" || removeStatus === "success") {
            handleCloseModal()
        }
    }, [createStatus, updateStatus, removeStatus])

    return (
        <ModalCommon
            open={openModal}
            handleClose={handleCloseModal}
            title="Add Category"
        >
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {!editData ? <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                <Select
                                    defaultValue=""
                                    name="category"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Category"
                                    disabled={loadingBoolean}
                                    onChange={formik.handleChange}
                                    value={formik.values.category}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.category && Boolean(formik.errors.category)}
                                    helperText={formik.touched.category && formik.errors.category}
                                >
                                    <MenuItem value="parent">{"Parent Category"}</MenuItem>
                                    {data?.map((item) =>
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    )}
                                </Select>
                                <Stack color={"#d32f2f"} fontSize="12px" ml={1.8} mt={0.5}>
                                    {formik.errors.category && formik.touched.category ? formik.errors.category : null}
                                </Stack>
                            </FormControl> : null}
                            {childCategory ? <FormControl fullWidth sx={{ mt: 1 }}>
                                <InputLabel id="demo-simple-select-label">Child Category</InputLabel>
                                <Select
                                    defaultValue=""
                                    name="childCategory"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Child Category"
                                    disabled={loadingBoolean}
                                    onChange={formik.handleChange}
                                    value={formik.values.childCategory}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.category && Boolean(formik.errors.childCategory)}
                                    helperText={formik.touched.category && formik.errors.childCategory}
                                >
                                    <MenuItem value="parent">{"Parent Category"}</MenuItem>
                                    {childCategory?.map((item) =>
                                        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    )}
                                </Select>
                            </FormControl> : null}
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <TextField
                                id="outlined-basic"
                                size="medium"
                                label="Category Name"
                                variant="outlined"
                                name="subCategories"
                                type="text"
                                disabled={loadingBoolean}
                                fullWidth
                                value={formik.values.subCategories}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.subCategories && Boolean(formik.errors.subCategories)}
                                helperText={formik.errors.subCategories && formik.touched.subCategories ? formik.errors.subCategories : null}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Stack mt={2}>
                                <Divider />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <DialogActions>
                                <LoadingButton type="submit" variant="contained" loading={createStatus === "pending" || updateStatus === "pending" ? true : false}>
                                    Save
                                </LoadingButton>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    onClick={handleCloseModal}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </ModalCommon>
    )
}

export default AddCategory