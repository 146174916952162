import React, { useEffect, useState } from "react";
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import ModalCommon from "../../../common/ModalCommon";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import useUsers from "../../../../redux/Users/selector";

function AddUser({ setOpenModal, openModal, setIsEditable, isEditable }) {
  const { create, update, user, status, createStatus, updateStatus, reset } =
    useUsers();
  const [licenseFileName, setLicenseFileName] = useState("");
  const [businessRegLetterFileName, setBusinessRegLetterFileName] =
    useState("");
  const [isAddressSame, setIsAddressSame] = useState(
    isEditable && user.address === user.billingAddress ? true : false
  );

  const businessRegLetterName = user?.businessRegLetter?.split('/').at(-1)
  const licenseName = user?.license?.split('/').at(-1)

  const loadingBoolean =
    (createStatus === "pending" ? true : false) ||
    (updateStatus === "pending" ? true : false) ||
    (isEditable && !Object.keys(user).length ? true : false);

  useEffect(() => {
    if (isEditable) {
      setLicenseFileName(licenseName);
      setBusinessRegLetterFileName(businessRegLetterName);
    }
  }, [businessRegLetterName, isEditable, licenseName]);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const addUserSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email()
      .trim()
      .matches(emailRegExp, "Please enter valid email")
      .required("Email is required"),
    phone: Yup.string()
      .min(10, "Phone number is too short")
      .max(10, "Phone number is too long")
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required"),
    whatsappNumber: Yup.string()
      .min(10, "Whatsapp number is too short")
      .max(10, "Whatsapp number is too long")
      .matches(phoneRegExp, "Whatsapp number number is not valid")
      .required("Whatsapp number is required"),
    organization: Yup.string().required("Organization is required"),
    city: Yup.string().required("City is required"),
    postalCode: Yup.string().required("Postal code is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    address: Yup.string().required("Address is required"),
    license: !isEditable && Yup.string().required("License is required"),
    businessRegLetter:
      !isEditable &&
      Yup.string().required("Business registration letter is required"),
    
    billingCity: Yup.string().when("isSameAddress", {
      is : false,
      then: Yup.string().required("City is required"),
    }),
    billingPostalCode: Yup.string().when("isSameAddress", {
      is : false,
      then: Yup.string().required("Postal code is required"),
    }),
     billingState: Yup.string().when("isSameAddress", {
      is : false,
      then: Yup.string().required("State is required"),
     }),
      billingCountry: Yup.string().when("isSameAddress", {
      is : false,
      then: Yup.string().required("Country is required"),
    }),
    billingAddress: Yup.string().when("isSameAddress", {
      is: false,
      then: Yup.string().required("Billing address is required"),
    }),
  });

  useEffect(() => {
    if (updateStatus === "success" || createStatus === "success") {
      handleCloseModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus, createStatus]);

  const formik = useFormik({
    initialValues: {
      firstName: isEditable && user ? user.firstName : "",
      lastName: isEditable && user ? user.lastName : "",
      email: isEditable && user ? user.email : "",
      phone: isEditable && user ? user.phone : "",
      whatsappNumber: isEditable && user ? user.whatsappNumber : "",
      organization: isEditable && user ? user.organization : "",
      license: "",
      businessRegLetter: "",
      city: "",
      postalCode: "",
      state: "",
      country: "",
      address: isEditable && user ? user.address : "",
      isSameAddress:
        isEditable && user && user.address === user.billingAddress
          ? true
          : false,
      billingCity: "",
      billingPostalCode: "",
      billingState: "",
      billingCountry: "",
      billingAddress: isEditable && !isAddressSame ? user.billingAddress : "",
    },
    enableReinitialize: true,
    validationSchema: addUserSchema,
    onSubmit: (values) => {
      let formData = new FormData();
      isEditable && formData.set("userId", user._id);
      formData.set("firstName", values?.firstName ?? "");
      formData.set("lastName", values?.lastName ?? "");
      formData.set("email", values?.email ?? "");
      formData.set("organization", values?.organization ?? "");
      formData.set("phone", values?.phone ?? "");
      formData.set("city", values?.city ?? "");
      formData.set("postalCode", values?.postalCode ?? "");
      formData.set("state", values?.state ?? "");
      formData.set("country", values?.country ?? "");
      formData.set("address", values?.address ?? "");
      formData.set("whatsappNumber", values?.whatsappNumber ?? "");
      if (values.isSameAddress) {
        formData.set("billingCity", values?.city ?? "");
        formData.set("billingPostalCode", values?.postalCode ?? "");
        formData.set("billingState", values?.state ?? "");
        formData.set("billingCountry", values?.country ?? "");
        formData.set("billingAddress", values.address);
      } else {
        formData.set("billingCity", values?.billingCity ?? "");
        formData.set("billingPostalCode", values?.billingPostalCode ?? "");
        formData.set("billingState", values?.billingState ?? "");
        formData.set("billingCountry", values?.billingCountry ?? "");
        formData.set("billingAddress", values.billingAddress);
      }

      if (isEditable) {
        values?.license && formData.append("license", values?.license ?? "");
        values?.businessRegLetter &&
          formData.append("businessRegLetter", values?.businessRegLetter ?? "");
      } else {
        if (values?.license && values?.businessRegLetter) {
          formData.append("license", values?.license ?? "");
          formData.append("businessRegLetter", values?.businessRegLetter ?? "");
        }
      }
      handleSubmitData(formData);
    },
  });

  const handleSubmitData = (formData) => {
    if (isEditable) {
      update(formData);
    } else {
      create(formData);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    formik.resetForm();
    setIsEditable(false);
    reset();
    setLicenseFileName("");
    setBusinessRegLetterFileName("");
  };

  const handleBusinessRegLetterChange = (e) => {
    if (e.target.files.length) {
      setBusinessRegLetterFileName(e.target.files[0].name);
      formik.setFieldValue("businessRegLetter", e.target.files[0]);
    }
  };

  const handleLicenseChange = (e) => {
    if (e.target.files.length) {
      setLicenseFileName(e.target?.files[0]?.name);
      formik.setFieldValue("license", e.target.files[0]);
    }
  };

  useEffect(() => {
    if (updateStatus === "success" || createStatus === "success") {
      handleCloseModal(false);
      setBusinessRegLetterFileName("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus, createStatus]);

  useEffect(() => {
    if (formik.values?.isSameAddress?.length) {
      setIsAddressSame(true);
    }

    if (formik.values.isSameAddress) {
      formik.setFieldTouched("billingAddress", false);
    }
  }, [formik.values.isSameAddress]);

  return (
    <ModalCommon
      open={openModal}
      handleClose={handleCloseModal}
      title={isEditable ? "Edit user" : "Add User"}
    >
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                id="outlined-basic"
                label="First name"
                variant="outlined"
                name="firstName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                fullWidth
                onBlur={formik.handleBlur}
                disabled={loadingBoolean}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={
                  formik.errors.firstName && formik.touched.firstName
                    ? formik.errors.firstName
                    : null
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="outlined-basic"
                label="Last name"
                variant="outlined"
                name="lastName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                fullWidth
                disabled={loadingBoolean}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={
                  formik.errors.lastName && formik.touched.lastName
                    ? formik.errors.lastName
                    : null
                }
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                name="email"
                type="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                fullWidth
                disabled={loadingBoolean}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={
                  formik.errors.email && formik.touched.email
                    ? formik.errors.email
                    : null
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="outlined-basic"
                label="Phone"
                variant="outlined"
                name="phone"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.phone}
                fullWidth
                disabled={loadingBoolean}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={
                  formik.errors.phone && formik.touched.phone
                    ? formik.errors.phone
                    : null
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="outlined-basic"
                label="Whatsapp number"
                variant="outlined"
                name="whatsappNumber"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.whatsappNumber}
                fullWidth
                onBlur={formik.handleBlur}
                disabled={loadingBoolean}
                error={
                  formik.touched.whatsappNumber &&
                  Boolean(formik.errors.whatsappNumber)
                }
                helperText={
                  formik.errors.whatsappNumber && formik.touched.whatsappNumber
                    ? formik.errors.whatsappNumber
                    : null
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="outlined-basic"
                label="Organization"
                variant="outlined"
                name="organization"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.organization}
                fullWidth
                disabled={loadingBoolean}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.organization &&
                  Boolean(formik.errors.organization)
                }
                helperText={
                  formik.errors.organization && formik.touched.organization
                    ? formik.errors.organization
                    : null
                }
              />
            </Grid>

            {/* File upload */}
            <Grid item md={6} xs={12}>
              <Button
                variant="outlined"
                component="label"
                size="large"
                fullWidth
                disabled={loadingBoolean}
                sx={{ textTransform: "capitalize" }}
              >
                Upload License
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={handleLicenseChange}
                />
              </Button>
              {licenseFileName && (
                <Stack direction="row" fontSize="13px">
                  {licenseFileName}
                </Stack>
              )}
              <Stack color={"#d32f2f"} fontSize="12px" ml={1.8}>
                {formik.errors.license && formik.touched.license
                  ? formik.errors.license
                  : null}
              </Stack>
            </Grid>

            {/* File upload */}
            <Grid item md={6} xs={12}>
              <Button
                variant="outlined"
                component="label"
                size="large"
                fullWidth
                disabled={loadingBoolean}
                sx={{ textTransform: "capitalize" }}
              >
                Upload Registration Letter
                <input
                  hidden
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={handleBusinessRegLetterChange}
                />
              </Button>
              {businessRegLetterFileName && (
                <Stack direction="row" fontSize="13px">
                  {businessRegLetterFileName}
                </Stack>
              )}
              <Stack color={"#d32f2f"} fontSize="12px" ml={1.8}>
                {formik.errors.businessRegLetter &&
                formik.touched.businessRegLetter
                  ? formik.errors.businessRegLetter
                  : null}
              </Stack>
            </Grid>

            {/* Shipping Address */}
            <Grid item md={12}>
              <Typography md={6} variant="h6" fontSize={16}>
                Shipping Address
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                style={{ padding: "0px" }}
                id="outlined-basic"
                label="City"
                variant="outlined"
                name="city"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.city}
                fullWidth
                disabled={loadingBoolean}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={
                  formik.errors.city && formik.touched.city
                    ? formik.errors.city
                    : null
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="outlined-basic"
                label="Postal code"
                variant="outlined"
                name="postalCode"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.postalCode}
                fullWidth
                disabled={loadingBoolean}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.postalCode && Boolean(formik.errors.postalCode)
                }
                helperText={
                  formik.errors.postalCode && formik.touched.postalCode
                    ? formik.errors.postalCode
                    : null
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="outlined-basic"
                label="State"
                variant="outlined"
                name="state"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.state}
                fullWidth
                disabled={loadingBoolean}
                onBlur={formik.handleBlur}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={
                  formik.errors.state && formik.touched.state
                    ? formik.errors.state
                    : null
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                id="outlined-basic"
                label="Country"
                variant="outlined"
                name="country"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.country}
                fullWidth
                disabled={loadingBoolean}
                onBlur={formik.handleBlur}
                error={formik.touched.state && Boolean(formik.errors.country)}
                helperText={
                  formik.errors.country && formik.touched.country
                    ? formik.errors.country
                    : null
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="filled-multiline-flexible"
                label="Address"
                variant="outlined"
                name="address"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.address}
                fullWidth
                multiline
                rows={3}
                disabled={loadingBoolean}
                onBlur={formik.handleBlur}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={
                  formik.errors.address && formik.touched.address
                    ? formik.errors.address
                    : null
                }
              />
            </Grid>

            {/* Same as shipping Address? */}
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isSameAddress"
                      onChange={formik.handleChange}
                      checked={formik.values.isSameAddress}
                      disabled={loadingBoolean}
                    />
                  }
                  label="Same as shipping Address?"
                />
              </FormGroup>
            </Grid>

            {/* Billing Address */}
            {!formik.values.isSameAddress ? (
              <>
                <Grid item md={12} pt={10}>
                  <Typography md={6} variant="h6" fontSize={16}>
                    Billing Address
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="City"
                    variant="outlined"
                    name="billingCity"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.billingCity}
                    fullWidth
                    disabled={loadingBoolean}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.billingCity &&
                      Boolean(formik.errors.billingCity)
                    }
                    helperText={
                      formik.errors.billingCity && formik.touched.billingCity
                        ? formik.errors.billingCity
                        : null
                    }
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Postal code"
                    variant="outlined"
                    name="billingPostalCode"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.billingPostalCode}
                    fullWidth
                    disabled={loadingBoolean}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.billingPostalCode &&
                      Boolean(formik.errors.billingPostalCode)
                    }
                    helperText={
                      formik.errors.billingPostalCode &&
                      formik.touched.billingPostalCode
                        ? formik.errors.billingPostalCode
                        : null
                    }
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="State"
                    variant="outlined"
                    name="billingState"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.billingState}
                    fullWidth
                    disabled={loadingBoolean}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.billingState &&
                      Boolean(formik.errors.billingState)
                    }
                    helperText={
                      formik.errors.billingState && formik.touched.billingState
                        ? formik.errors.billingState
                        : null
                    }
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Country"
                    variant="outlined"
                    name="billingCountry"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.billingCountry}
                    fullWidth
                    disabled={loadingBoolean}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.billingCountry &&
                      Boolean(formik.errors.billingCountry)
                    }
                    helperText={
                      formik.errors.billingCountry &&
                      formik.touched.billingCountry
                        ? formik.errors.billingCountry
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Address"
                    variant="outlined"
                    name="billingAddress"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.billingAddress}
                    fullWidth
                    multiline
                    rows={3}
                    disabled={loadingBoolean}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.billingAddress &&
                      Boolean(formik.errors.billingAddress)
                    }
                    helperText={
                      formik.errors.billingAddress &&
                      formik.touched.billingAddress
                        ? formik.errors.billingAddress
                        : null
                    }
                  />
                </Grid>
              </>
            ) : null}

            <Grid item xs={12}>
              <Stack mt={2}>
                <Divider />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <DialogActions>
                <LoadingButton
                  type="submit"
                  loading={
                    createStatus === "pending" ||
                      updateStatus === "pending" ||
                      status === "pending"
                      ? true
                      : false
                  }
                  variant="contained"
                >
                  Save
                </LoadingButton>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </ModalCommon>
  );
}

export default AddUser;
