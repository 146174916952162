import { useDispatch, useSelector } from "react-redux";
import {
  createUser,
  fetchAllUsers,
  fetchSingleUser,
  removeUser,
  resetUser,
  updateUser,
} from "./usersSlice";

const useUsers = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const user = useSelector((state) => state.users.user);
  const status = useSelector((state) => state.users.status);
  const createStatus = useSelector((state) => state.users.createStatus);
  const updateStatus = useSelector((state) => state.users.updateStatus);
  const removeStatus = useSelector((state) => state.users.removeStatus);
  const fetchUsers = (data) => dispatch(fetchAllUsers(data));
  const fetchUser = (data) => dispatch(fetchSingleUser(data));
  const create = (data) => dispatch(createUser(data));
  const update = (data) => dispatch(updateUser(data));
  const remove = (data) => dispatch(removeUser(data));
  const reset = (data) => dispatch(resetUser());
  return {
    users,
    user,
    status,
    createStatus,
    updateStatus,
    removeStatus,
    fetchUsers,
    fetchUser,
    create,
    update,
    remove,
    reset,
  };
};
export default useUsers;
