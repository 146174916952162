import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  DialogContent,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import MainContainer from "../../common/MainContainer";
import { Stack } from "@mui/system";
import Searchbar from "../../common/Searchbar";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DataTable from "../../common/DataTable";
import { format } from "date-fns";
import ModalCommon from "../../common/ModalCommon";
import useOrders from "../../../redux/Orders/selector";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function ReturnedOrders() {
  const [rows, setRows] = useState([]);
  const [filteredData, setFilteredData] = useState();
  const {
    returnedOrders,
    returnOrderActionApi,
    fetchReturnedOrders,
    returnOrderStatus,
  } = useOrders();
  const [openModal, setOpenModal] = useState(false);
  const [requestInfo, setRequestInfo] = useState({
    orderId: "",
    type: "",
  });

  const handleSearch = (e) => {
    if (e.target.value) {
      const res = returnedOrders.filter((item) => {
        const inputTxt = (
          item.id +
          item.name +
          item.price +
          item.quantity +
          item.status
        )
          .toLowerCase()
          .trim()
          .replace(/ /g, "");
        const text = e.target.value.toLowerCase().trim().replace(/ /g, "");
        return inputTxt.includes(text);
      });
      setFilteredData(res);
    } else {
      setFilteredData(returnedOrders);
    }
  };

  const handleSubmit = () => {
    const payload = {
      orderId: requestInfo?.orderId,
      status: requestInfo?.type === "approve" ? "Approved" : "Denied",
    };
    returnOrderActionApi(payload);
    setOpenModal(false);
    setRequestInfo({
      orderId: "",
      type: "",
    });
  };

  const columns = [
    { id: "name", label: "Customer Name" },
    { id: "price", label: "Price" },
    { id: "quantity", label: "Quantity" },
    { id: "createdAt", label: "Created" },
    {
      id: "id",
      label: "Actions",
      jsx: (value, row) => {
        return row?.returnedStatus ? (
          <>
            <Button
              style={{ color: row?.returnedStatus == "Denied" && "#ba000d" }}
            >
              {row?.returnedStatus}
              <Tooltip title={row?.returnReason} arrow >
                <InfoOutlinedIcon
                  style={{
                    color: row?.returnedStatus == "Denied" && "#ba000d",
                    marginBottom:'3px',
                    marginLeft:'5px'
                  }}
                />
              </Tooltip>
            </Button>
          </>
        ) : (
          <Stack direction="row" spacing={1}>
            <Avatar
              sx={{
                background: "#4267B2",
                width: 35,
                height: 35,
                cursor: "pointer",
              }}
              onClick={setOpenModal}
            >
              <Button
                sx={{ color: "#fff", width: "100%", height: "100%" }}
                onClick={() =>
                  setRequestInfo({ orderId: value, type: "approve" })
                }
              >
                <CheckIcon />
              </Button>
            </Avatar>
            <Avatar
              sx={{
                background: "#ba000d",
                width: 35,
                height: 35,
                cursor: "pointer",
              }}
              onClick={setOpenModal}
            >
              <Button
                sx={{ color: "#fff", width: "100%", height: "100%" }}
                onClick={() => setRequestInfo({ orderId: value, type: "deny" })}
              >
                <CloseIcon fontSize="small" />
              </Button>
            </Avatar>
          </Stack>
        );
      },
    },
  ];

  const fetchInitialData = async () => {
    const response = await fetchReturnedOrders();
    if (response && response?.payload && response?.payload) {
      const allOrders = response?.payload
        ?.map((order) => {
          const createDate = format(
            new Date(order.createdAt),
            "yyyy-MM-dd' 'HH:mm:ss"
          );
          return {
            id: order._id,
            returnReason: order?.returnReason,
            returnedStatus: order?.returnedStatus,
            returnRequest: order?.returnRequest,
            name: order?.user?.firstName + " " + order?.user?.lastName,
            price: `$${order.orderItems.reduce(
              (acc, curr) => acc + curr.price * curr.quantity,
              0
            )}`,
            quantity: order.orderItems.reduce(
              (acc, curr) => acc + curr.quantity,
              0
            ),
            createdAt: createDate,
            status: order?.status,
          };
        })
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setRows(allOrders);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <MainContainer title={"Return Orders"}>
      <Stack direction="row" justifyContent="space-between" pb={3}>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <Searchbar handleSearch={handleSearch} />
        </Grid>
      </Stack>
      <Divider />

      <DataTable
        columns={columns}
        rows={filteredData ?? rows}
        loading={returnOrderStatus === "pending"}
      />

      {/* Approve/Denny Order Request modal */}
      <ModalCommon
        open={openModal}
        handleClose={() => setOpenModal(false)}
        mxWidth="sm"
        isHeader={false}
      >
        <DialogContent>
          <Typography variant="h6" textAlign={"center"} margin={"28px 0px"}>
            Are you sure you want to {requestInfo?.type} this request?
          </Typography>
          <Box
            justifyContent={"center"}
            display={"flex"}
            gap={"20px"}
            margin={"20px 10px"}
          >
            <Button variant="outlined" onClick={() => setOpenModal(false)}>
              CANCEL
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              style={{
                backgroundColor: requestInfo?.type == "deny" && "#ba000d",
              }}
            >
              {requestInfo?.type.toLocaleUpperCase()}
            </Button>
          </Box>
        </DialogContent>
      </ModalCommon>
    </MainContainer>
  );
}
