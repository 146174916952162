import { useDispatch, useSelector } from "react-redux";
import { allCategory, createCategory, deleteCategory, editCategory, resetCategory } from "./categorySlice";

const useCategory = () => {
    const dispatch = useDispatch()

    const categories = useSelector((state) => state.categories.categories)
    const status = useSelector((state) => state.categories.status)
    const createStatus = useSelector((state) => state.categories.createStatus);
    const updateStatus = useSelector((state) => state.categories.updateStatus);
    const removeStatus = useSelector((state) => state.categories.removeStatus);

    const fetchCategories = (data) => dispatch(allCategory(data))
    const create = (data) => dispatch(createCategory(data))
    const update = (data) => dispatch(editCategory(data))
    const remove = (data) => dispatch(deleteCategory(data))
    const reset = (data) => dispatch(resetCategory(data))

    return {
        status,
        createStatus,
        updateStatus,
        removeStatus,
        categories,
        fetchCategories,
        create,
        update,
        remove,
        reset,
    }
}

export default useCategory

