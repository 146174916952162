import { useDispatch, useSelector } from "react-redux";
import {
  confirmOrder,
  fetchAllOrders,
  fetchReturnRequests,
  returnOrderApi,
} from "./ordersSlice";

const useOrders = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders.orders);
  const status = useSelector((state) => state.orders.status);
  const confirmStatus = useSelector((state) => state.orders.confirmStatus);
  const returnOrderStatus = useSelector(
    (state) => state.orders.returnOrderStatus
  );

  const returnedOrders = useSelector((state) => state.orders.returnedOrders);

  const fetchOrders = () => dispatch(fetchAllOrders());
  const orderConfirm = (data) => dispatch(confirmOrder(data));
  const returnOrderActionApi = (data) => dispatch(returnOrderApi(data));
  const fetchReturnedOrders = () => dispatch(fetchReturnRequests());

  return {
    orders,
    status,
    confirmStatus,
    returnOrderStatus,
    fetchOrders,
    orderConfirm,
    returnedOrders,
    returnOrderActionApi,
    fetchReturnedOrders,
  };
};
export default useOrders;
