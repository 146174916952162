import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiGet, ApiPost } from "../../component/api";

export const fetchAllProducts = createAsyncThunk(
  "product/fetchAllProducts",
  async (_, thunkAPI) => {
    try {
      const result = await ApiGet("product/all");
      if (result) {
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getProduct = createAsyncThunk(
  "product/getProduct",
  async (id, thunkAPI) => {
    try {
      const result = await ApiGet(`product/${id}`);
      if (result) {
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createProduct = createAsyncThunk(
  "product/createProduct",
  async (data, thunkAPI) => {
    try {
      const result = await ApiPost("product/create", data, {
        isFormData: true,
      });
      if (result) {
        toast.success(result.message, { autoClose: 3000 });
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editProduct = createAsyncThunk(
  "product/editProduct",
  async (data, thunkAPI) => {
    try {
      const result = await ApiPost("product/update", data, {
        isFormData: true,
      });
      if (result) {
        toast.success(result.message, { autoClose: 3000 });
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (id, thunkAPI) => {
    try {
      const result = await ApiPost("product/delete", id);
      if (result) {
        toast.success(result.message, { autoClose: 3000 });
        return thunkAPI.fulfillWithValue(id);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editProductVariant = createAsyncThunk(
  "product/editProductVariant",
  async (data, thunkAPI) => {
    try {
      const result = await ApiPost("product/variant/update", data);
      if (result) {
        toast.success(result.message, { autoClose: 3000 });

        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchAllAvailableProduct = createAsyncThunk(
  "product/fetchAllAvailableProduct",
  async (_, thunkAPI) => {
    try {
      const result = await ApiGet("product/allAvailableProduct");
      if (result) {
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchAllSoldProduct = createAsyncThunk(
  "product/fetchAllSoldProduct",
  async (_, thunkAPI) => {
    try {
      const result = await ApiGet("product/allSoldProduct");
      if (result) {
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState: {
    status: "idle",
    productData: [],
    availableProducts: [],
    soldProducts: [],
    productOne: [],
    message: "",
    error: "",
    availableProductStatus: "idle",
    soldProductStatus: "idle",
    productStatus: "idle",
    createStatus: "idle",
    updateStatus: "idle",
    removeStatus: "idle",
  },
  reducers: {
    resetProduct(state) {
      state.productStatus = "idle";
      state.updateStatus = "idle";
      state.createStatus = "idle";
      state.availableProductStatus = "idle";
      state.soldProductStatus = "idle";
      state.productOne = [];
    },
  },
  extraReducers: {
    [fetchAllProducts.pending]: (state) => {
      state.status = "pending";
    },
    [fetchAllProducts.fulfilled]: (state, action) => {
      state.status = "success";
      state.productData = action.payload;
      state.error = "";
    },
    [fetchAllProducts.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },

    [getProduct.pending]: (state) => {
      state.productStatus = "pending";
    },
    [getProduct.fulfilled]: (state, action) => {
      state.productStatus = "success";
      state.productOne = action.payload;
    },
    [getProduct.rejected]: (state, action) => {
      state.productStatus = "failed";
      state.error = action.payload;
    },

    [createProduct.pending]: (state) => {
      state.createStatus = "pending";
    },
    [createProduct.fulfilled]: (state, action) => {
      state.createStatus = "success";
      state.productData = [...state.productData, action.payload];
    },
    [createProduct.rejected]: (state, action) => {
      state.createStatus = "failed";
      state.error = action.payload;
    },

    [editProduct.pending]: (state) => {
      state.updateStatus = "pending";
    },
    [editProduct.fulfilled]: (state, action) => {
      state.updateStatus = "success";
      const findIndex = state.productData?.findIndex(
        (product) => product._id === action.payload._id
      );
      if (findIndex !== -1) {
        state.productData[findIndex] = action.payload;
      }
    },
    [editProduct.rejected]: (state, action) => {
      state.updateStatus = "failed";
      state.error = action.payload;
    },

    [deleteProduct.pending]: (state) => {
      state.removeStatus = "pending";
    },
    [deleteProduct.fulfilled]: (state, action) => {
      state.removeStatus = "success";
      state.productData = state.productData.filter(
        (item) => item._id !== action.payload.productId
      );
    },
    [deleteProduct.rejected]: (state, action) => {
      state.removeStatus = "failed";
      state.error = action.payload;
    },

    [fetchAllAvailableProduct.pending]: (state) => {
      state.availableProductStatus = "pending";
    },
    [fetchAllAvailableProduct.fulfilled]: (state, action) => {
      state.availableProductStatus = "success";
      state.availableProducts = action.payload;
      state.error = "";
    },
    [fetchAllAvailableProduct.rejected]: (state, action) => {
      state.availableProductStatus = "failed";
      state.error = action.payload;
    },

    [fetchAllSoldProduct.pending]: (state) => {
      state.soldProductStatus = "pending";
    },
    [fetchAllSoldProduct.fulfilled]: (state, action) => {
      state.soldProductStatus = "success";
      state.soldProducts = action.payload;
      state.error = "";
    },
    [fetchAllSoldProduct.rejected]: (state, action) => {
      state.soldProductStatus = "failed";
      state.error = action.payload;
    },
  },
});

export const { resetProduct } = productSlice.actions;

export default productSlice.reducer;
