import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MobileView from "./MobileView";
import Sidebardata from "./Sidebardata";
import { useDispatch } from "react-redux";
import { openstatus } from "./AppReducer";
import useStyleMainDisplay from "./MainDisplayStyle";
import { useHistory } from "react-router-dom";
import { Avatar } from "@mui/material";
import useAuth from "../../redux/Login/selector";
import { Stack } from "@mui/system";
import logo from "../images/logoWhite-small.svg";

const drawerWidth = 240;
const closedrawerWidth = 60;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  zIndex: 0,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: closedrawerWidth,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  backgroundColor: "#4267B2",
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
})(({ theme, open }) => ({
  width: `calc(100% - ${closedrawerWidth}px)`,
  marginLeft: closedrawerWidth,
  backgroundColor: "#4267B2",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const WebDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiPaper-root": { backgroundColor: "#222d32", border: "none" },
  ...(open && {
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar() {
  const { logout } = useAuth()
  const [open, setOpen] = React.useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    logout();
    history.push('/')
  }
  
  // const handleNotyficationClose = () => {
  //   setNotyOpen(null);
  // };
  // const handleSettingClose = () => {
  //   setSettingOpen(null);
  // };
  // const notyOpenBoolean = Boolean(notyOpen);

  const classes = useStyleMainDisplay();

  const handleDrawerOpen = () => {
    setOpen(!open);
    dispatch(openstatus(open))

  };

  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(openstatus(open))
  };

  return (
    <Box>
      <CssBaseline />
      <div className={classes.setwebdisplay}>
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              className={classes.setclass}
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 3,
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Cellular Shock
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
            <Box style={{ padding: 0 }}>
              <Stack direction="row" alignItems="center" spacing={1} onClick={handleLogout} sx={{ cursor: "pointer" }}>
                <ExitToAppIcon fontSize="small" />
                <div style={{ fontSize: "16px" }}>Logout</div>
              </Stack>
            </Box>
          </Toolbar>
        </AppBar>
        <WebDrawer variant="permanent" open={open} onClose={handleDrawerClose}>
          <DrawerHeader>
            <Avatar
              src={logo}
            />
            <Typography variant="button" color="common.white">
              &nbsp;&nbsp;&nbsp;&nbsp;Super Admin
            </Typography>
          </DrawerHeader>
          <Divider />
          <List sx={{ paddingTop: 0 }}>
            <Sidebardata />
          </List>
        </WebDrawer>
      </div>
      <div className={classes.setmobileview}>
        <MobileView />
      </div>
    </Box>
  );
}
