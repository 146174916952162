import { useDispatch, useSelector } from "react-redux";
import { addVariantQuantity, createProductVariant, deleteProductVariant, fetchProductVariant, reset, updateProductVariant } from "./productVariantSlice";

const useVariant = () => {
    const dispatch = useDispatch();

    const variant = useSelector((state) => state.productVariants.productVariantsData)
    const variantStatus = useSelector((state) => state.productVariants.status)
    const createQtyStatus = useSelector((state) => state.productVariants.createQtyStatus)
    const createStatus = useSelector((state) => state.productVariants.createStatus)
    const updateStatus = useSelector((state) => state.productVariants.updateStatus)
    const removeStatus = useSelector((state) => state.productVariants.removeStatus)

    const fetchVariant = (data) => dispatch(fetchProductVariant(data))
    const createVariant = (data) => dispatch(createProductVariant(data))
    const updateVariant = (data) => dispatch(updateProductVariant(data))
    const removeVariant = (data) => dispatch(deleteProductVariant(data))
    const addVariantQty = (data) => dispatch(addVariantQuantity(data))
    const resetVariant = (data) => dispatch(reset())

    return {
        variant,
        createStatus,
        updateStatus,
        removeStatus,
        variantStatus,
        createQtyStatus,
        fetchVariant,
        createVariant,
        updateVariant,
        removeVariant,
        addVariantQty,
        resetVariant,
    }
}

export default useVariant;