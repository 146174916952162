import { useDispatch, useSelector } from "react-redux";
import { createConfig, fetchAllConfig, updateConfig, resetSettings } from "./settingSlice";

const useSettings = () => {
  const dispatch = useDispatch();

  const status = useSelector((state) => state.setting.status);
  const createStatus = useSelector((state) => state.setting.createStatus);
  const updateStatus = useSelector((state) => state.setting.updateStatus);
  const configs = useSelector((state) => state.setting.allConfig);

  const fetchConfigs = (data) => dispatch(fetchAllConfig(data));
  const create = (data) => dispatch(createConfig(data));
  const update = (data) => dispatch(updateConfig(data));
  const reset = () => dispatch(resetSettings())

  return {
    configs,
    status,
    createStatus,
    updateStatus,
    fetchConfigs,
    create,
    update,
    reset,
  };
};
export default useSettings;
