import React from 'react'
import Container from "@mui/material/Container";
import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "100%",
  },
  setcontainer: {
    maxWidth: "100% !important",
    minHeight: "100vh",
    position: "relative",
    zIndex: 1,
    backgroundColor: "#f9fafc",
    paddingTop: "80px",
    paddingBottom: "40px !important ",
  },
  setheading: {
    padding: "5px",
    margin: "0 !important",
    color: "#202223",
    lineHeight: "32px",
    fontFamily: ["Poppins", "sans-serif", "!important"],
  },
  setpageheading: {
    maxWidth: "100% !important",
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#f9fafc",
  },
  setProductpaper: {
    textAlign: "left",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "100%",
    borderRadius: "10px",
    marginTop: "10px",
  },
}));

export default function MainContainer({ children, title }) {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xl" className={classes.setcontainer}>
      <div className={classes.setpageheading}>
        <Typography variant="h5" gutterBottom className={classes.setheading}>
          {title}
        </Typography>
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Paper className={classes.setProductpaper} elevation={1}>
            {children}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
