import React, { useEffect, useState } from "react";
import { Avatar, Button, Divider, Grid, Tooltip } from "@mui/material";
import MainContainer from "../../common/MainContainer";
import { Stack } from "@mui/system";
import Searchbar from "../../common/Searchbar";
import AddIcon from "@mui/icons-material/Add";
import useProducts from "../../../redux/Product/selector";
import useVariant from "../../../redux/ProductVariants/selector";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from "../../common/DataTable";
import AddProducts from "./add";
import VariantModal from "./variant";
import ConfirmationModal from "../../common/ConfirmationModal";
import useCategory from "../../../redux/Category/selector";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";

export default function Products() {
  const {
    availableProduct,
    soldProduct,
    products,
    product,
    status,
    productStatus,
    removeStatus,
    availableStatus,
    soldStatus,
    fetchAvailableProducts,
    fetchSoldProducts,
    fetchProducts,
    fetchProduct,
    remove,
    reset,
  } = useProducts();
  const { variant, variantStatus, fetchVariant, resetVariant } = useVariant();
  const { categories, fetchCategories } = useCategory();
  const [productsData, setProductsData] = useState([]);
  const productDataClone = [...productsData];
  const [openModal, setOpenModal] = useState(false);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [openModalVariant, setOpenModalVariant] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [deletedId, setDeletedId] = useState();

  // available and sold product list
  const availableProductData =
    availableProduct && availableProduct?.length > 0
      ? availableProduct.map((item) => {
        const productObj = item
        const varinatName = []
        Object.keys(productObj).forEach((key) => {
          if (key.includes("optionName") && productObj[key]) {
            varinatName.push(productObj[key])
          }
        })
        const name = item.productId.name + `${varinatName.length > 0 ? `${" - " + varinatName.join(" ")}` : ""}`
        return {
          deviceName: name,
          price: item.price,
          quantity: item.quantityAvailable,
          IMEINumber: item.itemDetails.length,
        };
      })
      : [];

  const soldProductData =
    soldProduct && soldProduct?.length > 0
      ? soldProduct.map((item) => {
        const productObj = item.productVariant
        const varinatName = []
        Object.keys(productObj).forEach((key) => {
          if (key.includes("optionName") && productObj[key]) {
            varinatName.push(productObj[key])
          }
        })
        const name = item.productVariant.productId.name + `${varinatName.length > 0 ? `${" - " + varinatName.join(" ")}` : ""}`
        return {
          deviceName: name,
          price: item.price,
          quantity: item.quantity,
          IMEINumber: item.itemDetails.length,
        };
      })
      : [];

  const csvProductHeaders = [
    { label: "Device Name", key: "deviceName" },
    { label: "Price", key: "price" },
    { label: "Quantity", key: "quantity" },
    { label: "IMEI Number", key: "IMEINumber" },
  ];

  const csvAvailableProductReport = {
    filename: "Available-products-report.csv",
    headers: csvProductHeaders,
    data: availableProductData,
  };

  const csvSoldProductReport = {
    filename: "Sold-products-report.csv",
    headers: csvProductHeaders,
    data: soldProductData,
  };
  // available and sold product list end

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenConfirmationModal = () => {
    setOpenModalConfirmation(true);
  };

  const handleCloseConfirmationModal = () => {
    setOpenModalConfirmation(false);
  };

  const handleOpenVariantModal = (id) => {
    fetchVariant(id);
    fetchProduct(id);
    setOpenModalVariant(true);
  };

  const handleEditProduct = (id) => {
    fetchProduct(id);
    fetchVariant(id);
    handleOpenModal();
    setIsEditable(true);
  };

  const handleDeleteProduct = (id) => {
    handleOpenConfirmationModal();
    setDeletedId(id);
  };

  const dispatchDelete = (id) => {
    remove({ productId: id });
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      const res = products.filter((item) => {
        const inputTxt = (item.name + item.code)
          .toLowerCase()
          .trim()
          .replace(/ /g, "");
        const text = e.target.value.toLowerCase().trim().replace(/ /g, "");
        return inputTxt.includes(text);
      });
      setProductsData(res);
    } else {
      setProductsData(products);
    }
  };

  useEffect(() => {
    if (status === "idle") {
      fetchProducts();
      fetchCategories();
      fetchAvailableProducts();
      fetchSoldProducts();
    }
    const productDataClone = [...products];
    setProductsData(
      productDataClone.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
    );
  }, [products, status, soldStatus, availableStatus]);

  const columns = [
    { id: "name", label: "Name" },
    { id: "description", label: "Description" },
    { id: "code", label: "Code" },
    {
      id: "updatedAt",
      label: "Updated At",
      jsx: (value) => (
        <span>{format(new Date(value), "yyyy-MM-dd' 'HH:mm:ss")}</span>
      ),
    },
    {
      id: "createdAt",
      label: "Created At",
      jsx: (value) => (
        <span>{format(new Date(value), "yyyy-MM-dd' 'HH:mm:ss")}</span>
      ),
    },
    {
      id: "_id",
      label: "Actions",
      jsx: (value) => (
        <Stack direction="row" spacing={1}>
          <Tooltip title="Add Variant">
            <Avatar
              sx={{
                background: "#f39c12",
                width: 35,
                height: 35,
                cursor: "pointer",
              }}
            >
              <Button
                sx={{ color: "#fff", width: "100%", height: "100%" }}
                onClick={() => handleOpenVariantModal(value)}
              >
                <AddIcon fontSize="medium" />
              </Button>
            </Avatar>
          </Tooltip>
          <Tooltip title="Edit Product">
            <Avatar
              sx={{
                background: "#4267B2",
                width: 35,
                height: 35,
                cursor: "pointer",
              }}
            >
              <Button
                sx={{ color: "#fff", width: "100%", height: "100%" }}
                onClick={() => handleEditProduct(value)}
              >
                <EditIcon fontSize="small" />
              </Button>
            </Avatar>
          </Tooltip>
          <Tooltip title="Remove Product">
            <Avatar
              sx={{
                background: "#ba000d",
                width: 35,
                height: 35,
                cursor: "pointer",
              }}
            >
              <Button
                sx={{ color: "#fff", width: "100%", height: "100%" }}
                onClick={() => handleDeleteProduct(value)}
              >
                <DeleteIcon fontSize="small" />
              </Button>
            </Avatar>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <MainContainer title={"Products"}>
      <Stack direction="row" justifyContent="space-between" pb={3}>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <Searchbar handleSearch={handleSearch} />
        </Grid>
        <Grid item xs={7} display={"flex"} justifyContent={"flex-end"} gap={1}>
          <Button
            sx={{ height: "100%" }}
            variant="outlined"
            color="primary"
            onClick={() => {
              if (availableProductData && availableProductData?.length == 0) {
                toast.error("Available products not found.", { autoClose: 3000 })
              }
            }}
          >
            {availableProductData && availableProductData?.length == 0
              ? 'Available Products'
              : <CSVLink
                {...csvAvailableProductReport}
                style={{ textDecoration: "none", height: '100%', width: '100%',display:'flex',alignItems:'center',justifyContent:'center' }}
              >
                <p style={{margin:'5px 15px'}}>Available Products</p>
              </CSVLink>
            }
          </Button>
          <Button sx={{ height: "100%" }} variant="outlined" color="primary"
            onClick={() => {
              if (soldProductData && soldProductData?.length == 0) {
                toast.error("Saled products not found.", { autoClose: 3000 })
              }
            }}
          >
            {soldProductData && soldProductData?.length == 0
              ? "Saled Products"
              : <CSVLink
                {...csvSoldProductReport}
                style={{ textDecoration: "none", height: '100%', width: '100%',display:'flex',alignItems:'center',justifyContent:'center'}}
              >
                <p style={{margin:'5px 15px'}}>Saled Products</p>
              </CSVLink>
            }

          </Button>
          <Button
            sx={{ height: "100%" }}
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenModal}
          >
            Add Product
          </Button>
        </Grid>
      </Stack>
      <Divider />

      <DataTable
        columns={columns}
        rows={productDataClone.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )}
        loading={status === "pending"}
      />

      <AddProducts
        openModal={openModal}
        setOpenModal={setOpenModal}
        setIsEditable={setIsEditable}
        isEditable={isEditable}
        data={product[0]}
        variantData={variant[0]}
        productStatus={productStatus}
        variantStatus={variantStatus}
        resetVariant={resetVariant}
        resetProduct={reset}
        categories={categories}
      />

      <VariantModal
        openModal={openModalVariant}
        setOpenModal={setOpenModalVariant}
        allVariants={variant}
        product={product}
        productStatus={productStatus}
      />

      <ConfirmationModal
        openModal={openModalConfirmation}
        removeStatus={removeStatus}
        handleClose={handleCloseConfirmationModal}
        id={deletedId}
        handleOk={dispatchDelete}
        content="Are you sure you want to Delete?"
      />
    </MainContainer>
  );
}
