import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiGet, ApiPost } from "../../component/api";

export const fetchAllOrders = createAsyncThunk(
  "category/fetchAllOrders",
  async (data, thunkAPI) => {
    try {
      const result = await ApiGet("order/all");
      if (result) {
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const confirmOrder = createAsyncThunk(
  "orders/confirmOrder",
  async (data, thunkAPI) => {
    try {
      const result = await ApiPost("admin/order/confirm", data);
      if (result) {
        return thunkAPI.fulfillWithValue({
          ...result.data,
          orderId: data.orderId,
        });
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchReturnRequests = createAsyncThunk(
  "orders/fetchReturnRequests",
  async (_, thunkAPI) => {
    try {
      const result = await ApiGet("admin/order/returnRequest/all");
      if (result) {
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const returnOrderApi = createAsyncThunk(
  "orders/returnOrderApi",
  async (data, thunkAPI) => {
    try {
      const result = await ApiPost("admin/order/return", data);
      if (result) {
        toast.success(result?.message, { autoClose: 3000 });
        return thunkAPI.fulfillWithValue(result);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    status: "idle",
    confirmStatus: "idle",
    returnOrderStatus: "idle",
    orders: [],
    returnedOrders: [],
  },
  extraReducers: {
    // fetch all orders
    [fetchAllOrders.pending]: (state) => {
      state.status = "pending";
    },
    [fetchAllOrders.fulfilled]: (state, action) => {
      state.status = "success";
      state.orders = action.payload;
    },
    [fetchAllOrders.rejected]: (state) => {
      state.status = "failed";
    },
    [confirmOrder.pending]: (state) => {
      state.confirmStatus = "pending";
    },
    [confirmOrder.fulfilled]: (state, action) => {
      state.confirmStatus = "success";
      state.orders = state.orders.map((order) => {
        if (order._id === action.payload.orderId) {
          return { ...order, status: "Confirm" };
        } else {
          return { ...order };
        }
      });
    },
    [confirmOrder.rejected]: (state) => {
      state.confirmStatus = "failed";
    },

    // fetch all returned orders
    [fetchReturnRequests.pending]: (state) => {
      state.returnOrderStatus = "pending";
    },
    [fetchReturnRequests.fulfilled]: (state, action) => {
      state.returnOrderStatus = "success";
      state.returnedOrders = action.payload;
    },
    [fetchReturnRequests.rejected]: (state) => {
      state.returnOrderStatus = "failed";
    },

    // Approve or Deny return request
    [returnOrderApi.pending]: (state) => {
      state.status = "pending";
    },
    [returnOrderApi.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [returnOrderApi.rejected]: (state) => {
      state.status = "failed";
    },
  },
});

export default ordersSlice.reducer;
