import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiGet, ApiPost } from "../../component/api";

const categoryFlattenFn = (data) => {
  var result = [];
  data?.forEach((a) => {
    result.push(a);
    if (Array.isArray(a.subCategories) && a.subCategories.length) {
      result = result.concat(categoryFlattenFn(a.subCategories));
    }
  });
  return result;
};

function replaceCategoryFlattenFn(arr) {
  var tree = [],
    mappedArr = {},
    arrElem,
    mappedElem;
  for (var i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem._id] = arrElem;
    mappedArr[arrElem._id]["subCategories"] = [];
  }
  for (var _id in mappedArr) {
    if (mappedArr.hasOwnProperty(_id)) {
      mappedElem = mappedArr[_id];
      if (mappedElem.parentCategory) {
        mappedArr[mappedElem["parentCategory"]]["subCategories"].push(mappedElem);
      }
      else {
        tree.push(mappedElem);
      }
    }
  }
  return tree;
}

export const allCategory = createAsyncThunk(
  "category/allCategory",
  async (data, thunkAPI) => {
    try {
      const result = await ApiGet("category/all");
      if (result.data) {
        return thunkAPI.fulfillWithValue(result.data);
      } else {
        toast.error(result?.message, { autoClose: 3000 });
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createCategory = createAsyncThunk(
  "category/createCategory",
  async (categoryData, thunkAPI) => {
    try {
      const result = await ApiPost("category/create", categoryData);
      if (result) {
        toast.success(result.message, { autoClose: 3000 });
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async (data, thunkAPI) => {
    try {
      const result = await ApiPost("category/delete", data);
      if (result) {
        toast.success(result.message, { autoClose: 3000 });
        return thunkAPI.fulfillWithValue(data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editCategory = createAsyncThunk(
  "category/editCategory",
  async (data, thunkAPI) => {
    try {
      const result = await ApiPost("category/update", data);
      if (result) {
        toast.success(result.message, { autoClose: 3000 });
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const categorySlice = createSlice({
  name: "categories",
  initialState: {
    status: "idle",
    createStatus: "idle",
    updateStatus: "idle",
    removeStatus: "idle",
    categories: [],
    message: "",
  },
  reducers: {
    resetCategory(state) {
      state.createStatus = "idle"
      state.updateStatus = "idle"
      state.removeStatus = "idle"
    },
  },
  extraReducers: {
    [allCategory.pending]: (state) => {
      state.status = "pending";
    },
    [allCategory.fulfilled]: (state, action) => {
      state.status = "success";
      state.categories = action.payload;
    },
    [allCategory.rejected]: (state) => {
      state.status = "failed";
    },

    [createCategory.pending]: (state) => {
      state.createStatus = "pending";
    },
    [createCategory.fulfilled]: (state, action) => {
      state.createStatus = "success";
      let currentCategories = categoryFlattenFn(state.categories);
      currentCategories.push(action.payload)
      state.categories = replaceCategoryFlattenFn(currentCategories);
    },
    [createCategory.rejected]: (state) => {
      state.createStatus = "failed";
    },

    [editCategory.pending]: (state) => {
      state.updateStatus = "pending";
    },
    [editCategory.fulfilled]: (state, action) => {
      state.updateStatus = "success";
      let currentCategories = categoryFlattenFn(state.categories);
      let index = currentCategories.findIndex((i) => i._id === action.payload._id);
      if (index !== -1) {
        currentCategories[index].name = action.payload.name;
        currentCategories[index].slug = action.payload.slug;
      }
      state.categories = replaceCategoryFlattenFn(currentCategories);
    },
    [editCategory.rejected]: (state) => {
      state.updateStatus = "failed";
    },

    [deleteCategory.pending]: (state) => {
      state.removeStatus = "pending";
    },
    [deleteCategory.fulfilled]: (state, action) => {
      state.removeStatus = "success";
      let currentCategories = categoryFlattenFn(state.categories);
      let filteredData = currentCategories.filter((i) => i._id !== action.payload.categoryId);
      state.categories = replaceCategoryFlattenFn(filteredData)
    },
    [deleteCategory.rejected]: (state) => {
      state.removeStatus = "failed";
    },
  },
});

export const { resetCategory } = categorySlice.actions;
export default categorySlice.reducer;
