import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import ModalCommon from "../../../../common/ModalCommon";
import * as Yup from "yup";
import useVariant from "../../../../../redux/ProductVariants/selector";

const AddQty = ({ openModal, setOpenModal, variant, sellerData }) => {
  const { createQtyStatus, addVariantQty, resetVariant } = useVariant();

  const loadingBoolean = 
    (createQtyStatus === "pending" ? true : false) || (!Object.keys(variant).length ? true : false);

  function sumDig(n) {
    let a = 0;
    while (n > 0) {
      a = a + (n % 10);
      n = parseInt(n / 10, 10);
    }
    return a;
  }

  function isValidIMEI(n) {
    let s = n?.toString();
    let len = s?.length;
    if (len !== 15) return false;

    let sum = 0;
    for (let i = len; i >= 1; i--) {
      let d = n % 10;
      if (i % 2 === 0) d = 2 * d;
      sum += sumDig(d);
      n = parseInt(n / 10, 10);
    }
    return sum % 10 === 0;
  }

  const addQtySchema = Yup.object().shape({
    quantity: Yup.number()
      .required("Quantity is required")
      .moreThan(0, "Quantity should not be zero or less than zero"),
    itemDetails: Yup.array().of(
      Yup.object().shape({
        seller: Yup.string().required("Seller is required"),
        IMEI: Yup.string()
          .required("IMEI number is required")
          .test({
            name: "IMEI is Required",
            message: "Please enter valid IMEI",
            test: (value) => isValidIMEI(value),
          }),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      productVariantId: variant?._id,
      quantity: "",
      itemDetails: [{ seller: "", IMEI: "" }],
    },
    enableReinitialize: true,
    validationSchema: addQtySchema,
    onSubmit: (values) => {
      addVariantQty(values);
    },
  });

  const handleQtyChange = (e) => {
    formik.setFieldValue("quantity", +e.target.value);
    formik.setTouched({}, false);
    if (e.target.value > 0) {
      let res = formik.values.itemDetails.filter(
        (item) => item.IMEI !== "" && item.seller !== ""
      );
      let itemDetailsArray = [];

      if (res.length < e.target.value) {
        itemDetailsArray.push(...res);
        const count = e.target.value - res.length;
        for (let i = 1; i <= count; i++) {
          itemDetailsArray.push({ seller: "", IMEI: "" });
        }
      } else if (res.length > e.target.value) {
        const kk = res.splice(0, e.target.value);
        itemDetailsArray.push(...kk);
      } else if (res.length == e.target.value) {
        const kk = res.splice(0, e.target.value);
        itemDetailsArray.push(...kk);
      }
      formik.setFieldValue("itemDetails", itemDetailsArray);
    }
  };

  const handleImeiChange = (e, index) => {
    const IMEIClone = [...formik.values.itemDetails];
    IMEIClone[index][e.target.name] = e.target.value;
    formik.setFieldValue("itemDetails", IMEIClone);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    formik.resetForm();
    resetVariant();
  };

  useEffect(() => {
    if (createQtyStatus === "success") {
      handleCloseModal();
    }
  }, [createQtyStatus]);

  return (
    <ModalCommon
      open={openModal}
      handleClose={handleCloseModal}
      title="Add Quantity"
      mxWidth="xs"
    >
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                label="Add Quantity"
                variant="outlined"
                name="quantity"
                type="text"
                fullWidth
                disabled={loadingBoolean}
                onChange={(e) => handleQtyChange(e)}
                value={formik.values.quantity}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.quantity && Boolean(formik.errors.quantity)
                }
                helperText={formik.touched.quantity && formik.errors.quantity}
              />
            </Grid>
            {formik.values.quantity > 0 &&
              formik.values.itemDetails.map((x, index) => {
                return (
                  <>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Seller
                        </InputLabel>
                        <Select
                          defaultValue={x.seller}
                          label="Seller"
                          name="seller"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          disabled={loadingBoolean}
                          onChange={(e) => handleImeiChange(e, index)}
                          value={formik.values.seller}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.itemDetails?.[index]?.seller &&
                            Boolean(formik.errors.itemDetails?.[index]?.seller)
                          }
                          helperText={
                            formik.touched.itemDetails?.[index]?.seller &&
                            formik.errors.itemDetails?.[index]?.seller
                          }
                        >
                          {sellerData?.map((seller) => {
                            return (
                              <MenuItem key={seller._id} value={seller._id}>
                                {seller.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <Stack
                          color={"#d32f2f"}
                          fontSize="12px"
                          ml={1.8}
                          mt={0.5}
                        >
                          {formik.errors.itemDetails?.[index]?.seller &&
                          formik.touched.itemDetails?.[index]?.seller
                            ? formik.errors.itemDetails?.[index]?.seller
                            : null}
                        </Stack>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="outlined-basic"
                        label="Add IMEI"
                        variant="outlined"
                        name="IMEI"
                        type="text"
                        fullWidth
                        disabled={loadingBoolean}
                        onChange={(e) => handleImeiChange(e, index)}
                        value={x.IMEI}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.itemDetails?.[index]?.IMEI &&
                          Boolean(formik.errors.itemDetails?.[index]?.IMEI)
                        }
                        helperText={
                          formik.touched.itemDetails?.[index]?.IMEI &&
                          formik.errors.itemDetails?.[index]?.IMEI
                        }
                      />
                    </Grid>
                  </>
                );
              })}
            <Grid item xs={12}>
              <DialogActions>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={createQtyStatus === "pending" ? true : false}
                >
                  Save
                </LoadingButton>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </ModalCommon>
  );
};

export default AddQty;
