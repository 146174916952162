import { useDispatch, useSelector } from "react-redux";
import {
  createSeller,
  fetchAllSellers,
  fetchSingleSeller,
  removeSeller,
  resetSeller,
  updateSeller,
} from "./sellerSlice";

const useSellers = () => {
  const dispatch = useDispatch();
  const sellers = useSelector((state) => state.seller.sellers);
  const seller = useSelector((state) => state.seller.seller);
  const status = useSelector((state) => state.seller.status);
  const createStatus = useSelector((state) => state.seller.createStatus);
  const updateStatus = useSelector((state) => state.seller.updateStatus);
  const removeStatus = useSelector((state) => state.seller.removeStatus);
  const fetchSellers = (data) => dispatch(fetchAllSellers(data));
  const fetchSeller = (data) => dispatch(fetchSingleSeller(data));
  const create = (data) => dispatch(createSeller(data));
  const update = (data) => dispatch(updateSeller(data));
  const remove = (data) => dispatch(removeSeller(data));
  const reset = (data) => dispatch(resetSeller());
  return {
    sellers,
    seller,
    status,
    createStatus,
    updateStatus,
    removeStatus,
    fetchSellers,
    fetchSeller,
    create,
    update,
    remove,
    reset,
  };
};
export default useSellers;
