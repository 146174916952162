import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Searchbar from "./Searchbar";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4267B2",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Row(props) {
  const { row, columns, collapsible, handleSubRowChange, rowIndex } = props;
  const [open, setOpen] = React.useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const collapsibleData = {
    rows: collapsible ? row.subRows : [],
    columns: collapsible ? row.subColums : [],
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      const res = row.subRows.filter((item) => {
        const inputTxt = item.IMEI.toLowerCase().trim().replace(/ /g, "");
        const text = e.target.value.toLowerCase().trim().replace(/ /g, "");
        return inputTxt.includes(text);
      });
      setFilteredData(res);
    } else {
      setFilteredData(row.subRows);
    }
  };

  useEffect(() => {
    if (row) {
      setFilteredData(row.subRows);
    }
  }, [row]);

  return (
    <React.Fragment>
      <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
        {columns.map((column) => {
          const value = row[column.id];
          return (
            <TableCell key={column.id}>
              {column.format && typeof value === "number"
                ? column.format(value)
                : column.jsx
                  ? column.jsx(value, row)
                  : value}
            </TableCell>
          );
        })}
        {collapsible && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {collapsible && (
        <>
          {row?.search && open && (
            <TableRow direction="row" justifyContent={"flex-end"}>
              <TableCell colSpan={5}>
                <Searchbar
                  handleSearch={handleSearch}
                  style={{ maxWidth: "200px" }}
                />
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        {collapsibleData.columns?.map((item) => {
                          return <TableCell>{item}</TableCell>;
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData.length > 0 ? (
                        filteredData.map((item, index) => {
                          return (
                            <TableRow>
                              <TableCell>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={item.checked}
                                      onChange={() => {
                                        handleSubRowChange(rowIndex, index);
                                      }}
                                    />
                                  }
                                  label=""
                                />
                              </TableCell>
                              {collapsibleData.columns?.map((col) => {
                                return (
                                  item[col] && (
                                    <TableCell component="th" scope="row">
                                      {item[col]}
                                    </TableCell>
                                  )
                                );
                              })}
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={5}>
                            No Result Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </React.Fragment>
  );
}

const DataTable = ({ columns, rows, loading = false, collapsible = false, handleSubRowChange, isStickyHeader = false }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <React.Fragment>
      <TableContainer sx={{ maxHeight: isStickyHeader ? 500 : '' }} >
        <Table stickyHeader={isStickyHeader ? true : false}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                collapsible ?
                  <StyledTableCell key={column.id} align={column.align}>
                    <b>{column.label}</b>
                  </StyledTableCell>
                  : <TableCell key={column.id} align={column.align}>
                    <b>{column.label}</b>
                  </TableCell>
              ))}
              {collapsible ? <StyledTableCell /> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length ? (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <Row row={row} columns={columns} rowIndex={index} collapsible={collapsible} handleSubRowChange={handleSubRowChange} />
                  );
                })
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={columns.length}>
                  {loading ? "Loading..." : "Data not found"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
};
export default DataTable;
