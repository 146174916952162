import React, { useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import { Button, DialogActions, Typography } from '@mui/material';
import { makeStyles } from "@material-ui/styles";
import { DialogContent } from '@material-ui/core';
import { LoadingButton } from '@mui/lab';

const useStyles = makeStyles((theme) => ({
    dialogMain: {
        "& .MuiPaper-root": {
            minWidth: "450px",
            textAlign: "center",
            padding: "10px",
        }
    },
    dialogTitle: {
        padding: "20px 30px !important",
        margin: 0,
    },
    iconButton: {
        position: 'absolute !important',
        right: 30,
        top: 17,
    },
    actionButtonWrapper: {
        justifyContent: "center !important",
        marginTop: "25px",
        gap: "10px",
    }
}))

const ConfirmationModal = ({ openModal, handleClose, content, id, handleOk, removeStatus }) => {
    const classes = useStyles();

    const handleDelete = () => {
        handleOk(id)
    }

    useEffect(() => {
        if (removeStatus === "success") {
            handleClose()
        }
    }, [removeStatus])

    return (
        <Dialog
            open={openModal}
            aria-labelledby="draggable-dialog-title"
            className={classes.dialogMain}
        >
            <DialogContent>
                <Typography variant='h6'>{content}</Typography>
                <DialogActions className={classes.actionButtonWrapper}>
                    <LoadingButton
                        variant="contained"
                        size="medium"
                        onClick={handleDelete}
                        loading={removeStatus === "pending" ? true : false}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        variant="outlined"
                        size="medium"
                        onClick={() => handleClose()}
                    >
                        No
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default ConfirmationModal