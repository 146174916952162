import React, { useEffect } from "react";
import { Button, Divider, Grid } from "@mui/material";
import MainContainer from "../../common/MainContainer";
import { Stack } from "@mui/system";
import Searchbar from "../../common/Searchbar";
import useOrders from "../../../redux/Orders/selector";
import DataTable from "../../common/DataTable";
import { useState } from "react";
import ConfirmOrder from "./confirm";
import { format } from "date-fns";


export default function Orders() {
  const { orders, status, fetchOrders } = useOrders();
  const [openModal, setOpenModal] = useState()
  const [filteredData, setFilteredData] = useState();
  const [selectedOrder, setSelectedOrder] = useState({})

  let allOrders =
    orders.map((order) => {
      const createDate = format(new Date(order.createdAt), "yyyy-MM-dd' 'HH:mm:ss")
      return {
        id: order._id,
        name: order.user.firstName + " " + order.user.lastName,
        price: `$${order.orderItems.reduce(
          (acc, curr) => acc + curr.price * curr.quantity,
          0
        )}`,
        quantity: order.orderItems.reduce(
          (acc, curr) => acc + curr.quantity,
          0
        ),
        createdAt: createDate,
        status: order?.status,
      };
    }).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const columns = [
    { id: "name", label: "Customer Name" },
    { id: "price", label: "Price" },
    { id: "quantity", label: "Quantity" },
    { id: "createdAt", label: "Created" },
    {
      id: "status",
      label: "Status",
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      jsx: (value, row) => <Button onClick={() => handleOrderConfirm(row.id)} disabled={value && value === "Confirm"}>{value && value === "Confirm" ? "Confirmed" : "Pending"}</Button>,
    },
  ];

  const handleOrderConfirm = (Id) => {
    setOpenModal(true)
    const order = orders?.find((item) => item._id === Id)
    setSelectedOrder(order)
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      const res = allOrders.filter((item) => {
        const inputTxt = (item.id + item.name + item.price + item.quantity + item.status).toLowerCase().trim().replace(/ /g, "")
        const text = (e.target.value).toLowerCase().trim().replace(/ /g, "")
        return inputTxt.includes(text)
      })
      setFilteredData(res)
    }
    else {
      setFilteredData(allOrders)
    }
  }


  useEffect(() => {
    if (status === "idle") {
      fetchOrders();
    }
  }, [fetchOrders, status]);

  return (
    <React.Fragment>
      <MainContainer title={"Orders"}>
        <Stack direction="row" justifyContent="space-between" pb={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Searchbar handleSearch={handleSearch} />
          </Grid>
        </Stack>
        <Divider />
        <DataTable
          columns={columns}
          rows={filteredData ?? allOrders}
          loading={status === "pending"}
        />
      </MainContainer>

      {/* Confirm Order */}
      <ConfirmOrder openModal={openModal} handleCloseModal={handleCloseModal} order={selectedOrder} />
    </React.Fragment>
  );
}
