import React from 'react'
import { Stack } from "@mui/system";
import { PhotoCamera } from "@material-ui/icons";
import { Typography } from '@mui/material';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    inputWrapper: {
        border: "1px dashed #4267b2",
        position: "relative",
        marginRight: 10,
        borderRadius: "13px",
        width: "100%",
        height: "127px",
        backgroundColor: "#b7ccf7"
    },
    imageInput: {
        opacity: 0,
        position: "absolute",
        zIndex: "1",
        height: "100%",
        top: "0%",
        width: "100%",
        left: "0",
        cursor: "pointer",
    },
    inputLabel: {
        position: "absolute",
        left: "50%",
        cursor: "pointer",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        transform: " translateX(-50%)",
    },
    PhotoCameraIcon: {
        fill: "#4267b2",
    },
    inputPlaceholder: {
        color: "#4267b2"
    },
}));

const UploadImageField = ({
    onChange,
    value,
    name,
    error,
    placeholder,
}) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <div className={classes.inputWrapper}>
                <input
                    type="file"
                    accept="image/*"
                    name={name}
                    onChange={(e) => onChange(e)}
                    className={classes.imageInput}
                />
                <label className={classes.inputLabel}>
                    {value
                        ? <img src={typeof value !== 'string' ? URL.createObjectURL(value) : value} width={100} height={120} alt="ProductImage" />
                        : <>
                            <PhotoCamera className={classes.PhotoCameraIcon} />
                            <Typography noWrap className={classes.inputPlaceholder}>
                                {placeholder}
                            </Typography>
                        </>
                    }
                </label>
            </div>
            <Stack color={"#d32f2f"} fontSize="12px" ml={1.8}>
                {error}
            </Stack>
        </React.Fragment>
    )
}

export default UploadImageField
