import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "0 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    border: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '25px !important',
  },
  iconButton: {
    padding: "10px !important",
  },
  divider: {
    height: "28px !important",
    margin: "4px !important"
  },
  inputBase: {
    marginLeft: 8,
    flex: 1
  }
}))

export default function Searchbar({ handleSearch }) {
  const classes = useStyles();
  return (
    <Paper
      elevation={0}
      component="form"
      className={classes.paper}
    >
      <IconButton type="button" aria-label="search" className={classes.iconButton}>
        <SearchIcon />
      </IconButton>
      <Divider orientation="vertical" className={classes.divider} />
      <InputBase
        placeholder="Search..."
        inputProps={{ "aria-label": "search..." }}
        className={classes.inputBase}
        onChange={handleSearch}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      />
    </Paper>
  );
}
