import React, { useState } from "react";
import Container from "@mui/material/Container";
import { Paper, Grid, makeStyles } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyleAuth = makeStyles((theme) => ({
  setloginbackpaper: {
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
    display: "flex !important",
    justifyContent: "center",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "510px",
    borderRadius: "10px",
  },
  setcontainer: {
    display: "flex !important",
    maxWidth: "100% !important",
    minHeight: "100vh",
    position: "relative",
    zIndex: 1,
    backgroundColor: "aliceblue",
    paddingTop: "40px",
  },
  setsignuilink: {
    textDecoration: "none",
    cursor: "pointer",
    fontWeight: "600 !important",
    marginLeft: "5px !important",
    color: "#7f2121 !important",
  },
  setbottomtypography: {
    display: "flex",
    padding: "24px 0 0",
    justifyContent: "center",
  },
  setloginbutton: {
    marginTop: "25px !important",
    backgroundColor: "#367fa9 !important",
    height: "50px",
  },
  setacctypography: {
    fontSize: "14px !important",
    lineHeight: "21px !important",
    color: "#4c4747",
  },
  setinput: {
    display: "grid",
  },
  setlabel: {
    display: "flex",
    fontFamily: ["Poppins", "sans-serif", "!important"],
    fontSize: "14px",
    lineHeight: "21px",
    marginRight: "10px",
    marginBottom: "2px",
    marginLeft: "4px",
  },
  seterrorlabel: {
    margin: 0,
    display: "flex",
    color: "#7f2121",
  },
}));

export default function SignUp() {
  const [regname, setRegname] = useState("");
  const [regEmail, setRegEmail] = useState("");
  const [regPassword, setRegPassword] = useState("");
  const [regRepetPassword, setRegRepetPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const classes = useStyleAuth();
  
  const handlename = (e) => {
    setRegname(e.target.value);
  };
  const handleemail = (e) => {
    setRegEmail(e.target.value);
  };

  const handlepassword = (e) => {
    setRegPassword(e.target.value);
  };

  const handlerepeatpassword = (e) => {
    setRegRepetPassword(e.target.value);
  };

  const handlesenddata = (e) => {
    e.preventDefault();
    if (
      !regname ||
      regname.length < 2 ||
      regname.length > 20 ||
      !regEmail ||
      !regPassword ||
      regPassword.length > 20 ||
      regPassword.length < 6 ||
      !regRepetPassword ||
      regRepetPassword !== regPassword
    ) {
      if (!regname) {
        errors.name = "name Required";
      } else if (regname.length < 2) {
        errors.name = "Enter minimum 2 charecter";
      } else if (regname.length > 20) {
        errors.name = "Enter maximun 20 charecter";
      } else {
        errors.name = "";
      }
      if (!regEmail) {
        errors.email = "email is Require";
      }
      else {
        errors.email = "";
      }

      if (!regPassword) {
        errors.password = "password Required";
      } else if (regPassword.length > 20) {
        errors.password = "Enter max 20 charecter";
      } else if (regPassword.length < 6) {
        errors.password = "Enter Minimun 6 charecter";
      } else {
        errors.password = "";
      }

      if (!regRepetPassword) {
        errors.r_password = "conform password Required";
      } else if (regRepetPassword !== regPassword) {
        errors.r_password = "your conform password is different";
      } else {
        errors.r_password = "";
      }

      setErrors({ ...errors, [e.target.name]: e.target.value });
      setTimeout(() => {
        setErrors([]);
      }, 5000);
    } else {
      setErrors([]);
    }
  };

  return (
    <>
      <ToastContainer position="top-right" closeOnClick />
      <Container
        component="main"
        maxWidth="xl"
        className={classes.setcontainer}
      >
        <Paper className={classes.setloginbackpaper} elevation={5}>
          <Typography variant="h4" gutterBottom sx={{ color: "#3c8dbc" }}>
            Register
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.setinputlayout}>
              <div className={classes.setinput}>
                <label className={classes.setlabel}>Name :</label>
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  label={regname === "" ? "Enter Name." : ""}
                  InputLabelProps={{ shrink: false }}
                  value={regname}
                  onChange={handlename}
                />
              </div>
              {errors.name && (
                <p className={classes.seterrorlabel}>{errors.name}</p>
              )}
            </Grid>
            <Grid item xs={12} className={classes.setinputlayout}>
              <div className={classes.setinput}>
                <label className={classes.setlabel}>Email :</label>
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="email"
                  variant="outlined"
                  label={regEmail === "" ? "Enter email." : ""}
                  InputLabelProps={{ shrink: false }}
                  value={regEmail}
                  onChange={handleemail}
                />
              </div>
              {errors.email && (
                <p className={classes.seterrorlabel}>{errors.email}</p>
              )}
            </Grid>
            <Grid item xs={12} className={classes.setinputlayout}>
              <div className={classes.setinput}>
                <label className={classes.setlabel}>Password :</label>
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  label={regPassword === "" ? "Enter password." : ""}
                  InputLabelProps={{ shrink: false }}
                  value={regPassword}
                  onChange={handlepassword}
                />
              </div>
              {errors.password && (
                <p className={classes.seterrorlabel}>{errors.password}</p>
              )}
            </Grid>
            <Grid item xs={12} className={classes.setinputlayout}>
              <div className={classes.setinput}>
                <label className={classes.setlabel}>Retype Password :</label>
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  label={regRepetPassword === "" ? "Enter Repeat password." : ""}
                  InputLabelProps={{ shrink: false }}
                  value={regRepetPassword}
                  onChange={handlerepeatpassword}
                />
              </div>
              {errors.r_password && (
                <p className={classes.seterrorlabel}>{errors.r_password}</p>
              )}
            </Grid>
          </Grid>

          <Button
            variant="contained"
            className={classes.setloginbutton}
            onClick={handlesenddata}
          >
            Login
          </Button>
          <div className={classes.setbottomtypography}>
            <Typography
              variant="body2"
              className={classes.setacctypography}
              gutterBottom
            >
              Already have an Account?
            </Typography>
            <Typography
              className={classes.setsignuilink}
              variant="body2"
              noWrap
              component={Link}
              to="/"
              color="textPrimary"
              underline="none"
            >
              Sign in.
            </Typography>
          </div>
        </Paper>
      </Container>
    </>
  );
}
