import React, { useState } from "react";
import { Avatar, Button, Divider, Grid, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MainContainer from "../../common/MainContainer";
import DataTable from "../../common/DataTable";
import { Stack } from "@mui/system";
import Searchbar from "../../common/Searchbar";
import AddIcon from "@mui/icons-material/Add";
import AddConfig from "./add";
import useSettings from "../../../redux/Settings/selector";
import { useEffect } from "react";
import { format } from "date-fns";

export default function Settings() {
  const { status, configs, createStatus, updateStatus, fetchConfigs, reset } =
    useSettings();
  const [openModal, setOpenModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [allConfig, setAllConfig] = useState([]);
  const [singleConfig, setSingleConfig] = useState({});
  const [filteredData, setFilteredData] = useState({});

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      const res = allConfig?.filter((item) => {
        const inputTxt = (item.name + item.value)
          .toLowerCase()
          .trim()
          .replace(/ /g, "");
        const text = e.target.value.toLowerCase().trim().replace(/ /g, "");
        return inputTxt.includes(text);
      });
      setFilteredData(
        res.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
    } else {
      setFilteredData(
        allConfig.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
    }
  };

  const handleEditConfigs = (id) => {
    const result = allConfig.find((item) => {
      return item._id === id;
    });
    setSingleConfig(result);
    handleOpenModal();
    setIsEditable(true);
  };

  useEffect(() => {
    if (configs) {
      setAllConfig(configs);
      const allConfigClone = [...allConfig].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setFilteredData(allConfigClone);
    }
  }, [configs, allConfig]);

  useEffect(() => {
    if (status === "idle") {
      fetchConfigs();
    }
  }, [fetchConfigs, status]);

  const columns = [
    { id: "name", label: "Name" },
    { id: "value", label: "Value" },
    {
      id: "createdAt",
      label: "Created At",
      jsx: (value) => (
        <span>{format(new Date(value), "yyyy-MM-dd' 'HH:mm:ss")}</span>
      ),
    },
    {
      id: "updatedAt",
      label: "Updated At",
      jsx: (value) => (
        <span>{format(new Date(value), "yyyy-MM-dd' 'HH:mm:ss")}</span>
      ),
    },
    {
      id: "_id",
      label: "Actions",
      jsx: (value) => (
        <Stack direction="row" spacing={1}>
          <Tooltip title="Edit Product">
            <Avatar
              sx={{
                background: "#4267B2",
                width: 35,
                height: 35,
                cursor: "pointer",
              }}
            >
              <Button
                sx={{ color: "#fff", width: "100%", height: "100%" }}
                onClick={() => handleEditConfigs(value)}
              >
                <EditIcon fontSize="small" />
              </Button>
            </Avatar>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <MainContainer title={"Settings"}>
      <Stack direction="row" justifyContent="space-between" pb={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Searchbar handleSearch={handleSearch} />
        </Grid>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenModal}
        >
          Add Config
        </Button>
      </Stack>
      <Divider />
      <DataTable
        columns={columns}
        rows={
          filteredData.length > 0
            ? filteredData.map((item) => {
                if (item.type === "image") {
                  return { ...item, value: item.name };
                } else {
                  return item;
                }
              })
            : allConfig.map((item) => {
                if (item.type === "image") {
                  return { ...item, value: item.name };
                } else {
                  return item;
                }
              })
        }
        loading={status === "pending" ? true : false}
      />

      <AddConfig
        openModal={openModal}
        setOpenModal={setOpenModal}
        setIsEditable={setIsEditable}
        isEditable={isEditable}
        singleConfig={singleConfig}
        reset={reset}
        createStatus={createStatus}
        updateStatus={updateStatus}
      />
    </MainContainer>
  );
}
