import "./App.css";
import { ThemeProvider } from "@material-ui/styles";
import store, { persistor } from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import Login from "./component/authenticate/Login";
import SignUp from "./component/authenticate/SignUp";
import useStyleMainDisplay from "./component/header&sidebar/MainDisplayStyle";
import Sidebar from "./component/header&sidebar/Sidebar";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { createTheme } from "@material-ui/core";
import Users from "./component/masterpage/users";
import Seller from "./component/masterpage/seller";
import Category from "./component/masterpage/category";
import Products from "./component/masterpage/products";
import Orders from "./component/masterpage/orders";
import Settings from "./component/masterpage/settings";
import ReturnedOrders from "./component/masterpage/returnedOrders";

const Layout = ({ children }) => {
  const classes = useStyleMainDisplay();
  return (
    <div className={classes.setdisplay}>
      <Sidebar />
      {children}
    </div>
  );
};

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#4267B2",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 426,
        md: 769,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),

      h5: {
        fontFamily: ["Poppins", "sans-serif"],
        fontWeight: 400,
        fontSize: "1.5rem",
        lineHeight: "1.334",
        letterSpacing: "0em",
      },
      h4: {
        fontFamily: ["Poppins", "sans-serif"],
      },
      h2: {
        fontFamily: ["Poppins", "sans-serif"],
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastContainer position="top-right" closeOnClick />
          <BrowserRouter>
            <Switch>
              <Route exact path={`/login`} component={Login} />
              <Route exact path={`/register`} component={SignUp} />
              <Layout>
                <PrivateRoute exact path="/" component={Users} />
                <PrivateRoute exact path="/users" component={Users} />
                <PrivateRoute exact path="/seller" component={Seller} />
                <PrivateRoute exact path="/categories" component={Category} />
                <PrivateRoute exact path="/products" component={Products} />
                <PrivateRoute exact path="/returned-orders" component={ReturnedOrders} />
                <PrivateRoute exact path="/orders" component={Orders} />
                <PrivateRoute exact path="/settings" component={Settings} />
              </Layout>
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
