import { useDispatch, useSelector } from "react-redux";
import { fetchLogin, fetchLogout } from './loginSlice'

const useAuth = () => {
  const dispatch = useDispatch()
  const loginStatus = useSelector((state) => state.login.status);
  const loginToken = useSelector((state) => state.login.token);
  const login = (data) => dispatch(fetchLogin(data));
  const logout = (data) => dispatch(fetchLogout(data));
  return { loginStatus, loginToken, login, logout };
}
export default useAuth;