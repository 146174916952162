import React, { useEffect, useState } from "react";
import {
  Button,
  Avatar,
  Tooltip,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import MainContainer from "../../common/MainContainer";
import { Stack } from "@mui/system";
import Searchbar from "../../common/Searchbar";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCategory from "./add";
import { makeStyles } from "@material-ui/styles";
import useCategory from "../../../redux/Category/selector";
import ConfirmationModal from "../../common/ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    '& b': {
      display: "block",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: 14,
      fontFamily: "Poppins,sans-serif",
    }
  },

  accordion: {
    backgroundColor: "#fff !important",
    margin: "0 !important",
    boxShadow: "none !important",
    borderTop: "1px solid #e0e0e0",
    '&::before': {
      display: "none",
    },
  },
  accordionSummaryCss: {
    opacity: "1 !important",
    minHeight: "auto !important",
    gridGap: 8,
    pointerEvents: "inherit !important",
    cursor: "pointer !important",
    '& > div': {
      margin: "16px 0 !important",

    },
  },
  name: {
    color: "#000000de",
    fontSize: "14px !important",
    fontFamily: "Poppins,sans-serif !important",
    fontWeight: "400 !important",
  },
  accordionDetails: {
    padding: "0 15px !important",
  },
  toolTip: {
    backgroundColor: "#4267B2 !important",
    width: "35px !important",
    height: "35px !important",
    cursor: "pointer",
  },
}))

export default function Category() {
  const { categories, removeStatus, status, fetchCategories, remove } = useCategory();
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState();
  const [isEditable, setIsEditable] = useState(false);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [deletedId, setDeletedId] = useState();
  function renameKey(arr) {
    return arr.map((item) =>
      item?.subCategories && item.subCategories.length ? ({
        label: item?.name,
        value: item?._id,
        children: renameKey(item?.subCategories),
        slug: item?.slug
      }) : ({
        label: item?.name,
        value: item?._id,
        children: item?.subCategories,
        slug: item?.slug
      }))
  }

  const handleOpenModal = () => {
    setOpenModal(true);
    setEditData();
  };

  const handleOpenConfirmationModal = () => {
    setOpenModalConfirmation(true)
  }

  const handleCloseConfirmationModal = () => {
    setOpenModalConfirmation(false)
  }

  const handleDelete = (id) => {
    handleOpenConfirmationModal()
    setDeletedId(id)

  }

  const dispatchDelete = (id) => {
    remove({ "categoryId": id })
  }

  const handleEdit = (data) => {
    handleOpenModal()
    setIsEditable(true)
    setEditData(data)
  };

  useEffect(() => {
    if (status === "idle") {
      fetchCategories()
    }
  }, [fetchCategories, status])

  return (
    <MainContainer title={"Category"}>
      <Stack direction="row" justifyContent="flex-end" pb={3}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenModal}
        >
          Add Category
        </Button>
      </Stack>

      {/* <Accordion> */}
      <Accordion disabled={true} className={classes.accordion}>
        <AccordionSummary
          className={`${classes.accTitle} ${classes.accordionSummaryCss}`}
          expandIcon={false}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            spacing={2}
            className={classes.mainTitle}
          >
            <b>All Categories</b>
            <b>Action</b>
          </Stack>
        </AccordionSummary>
      </Accordion>
      {categories && categories.length ? (
        categories.map((item) => {
          return (
            <Accordion
              disabled={item?.subCategories.length ? false : true}
              className={classes.accordion}
            >
              <AccordionSummary
                className={classes.accordionSummaryCss}
                key={item.name}
                expandIcon={
                  item?.subCategories.length ? (
                    <Avatar
                      className={classes.toolTip}
                      sx={{ backgroundColor: "#f39c12 !important" }}
                    >
                      <ExpandMoreIcon fontSize="small" />
                    </Avatar>
                  ) : null
                }
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  spacing={2}
                >
                  <Typography className={classes.name}>
                    {item.name}
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Tooltip title="Edit">
                      <Avatar className={classes.toolTip}>
                        <EditIcon
                          fontSize="small"
                          onClick={() => handleEdit(item)}
                        />
                      </Avatar>
                    </Tooltip>
                    {!item?.subCategories.length && (
                      <Tooltip title="Remove">
                        <Avatar
                          className={classes.toolTip}
                          sx={{ background: "#ba000d !important" }}
                        >
                          <DeleteIcon
                            fontSize="small"
                            onClick={() => handleDelete(item._id)}
                          />
                        </Avatar>
                      </Tooltip>
                    )}
                  </Stack>
                </Stack>
              </AccordionSummary>
              {item?.subCategories.map((subCat) => {
                return (
                  <AccordionDetails
                    key={subCat.name}
                    className={classes.accordionDetails}
                  >
                    <Accordion
                      disabled={subCat?.subCategories.length ? false : true}
                      className={classes.accordion}
                    >
                      <AccordionSummary
                        className={classes.accordionSummaryCss}
                        key={subCat.name}
                        expandIcon={
                          subCat?.subCategories.length ? (
                            <Avatar
                              className={classes.toolTip}
                              sx={{ background: "#f39c12 !important" }}
                            >
                              <ExpandMoreIcon />
                            </Avatar>
                          ) : null
                        }
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          width="100%"
                          spacing={2}
                        >
                          <Typography className={classes.name}>
                            {subCat.name}
                          </Typography>
                          <Stack direction="row" spacing={1}>
                            <Tooltip title="Edit">
                              <Avatar className={classes.toolTip}>
                                <EditIcon
                                  fontSize="small"
                                  onClick={() => handleEdit(subCat)}
                                />
                              </Avatar>
                            </Tooltip>
                            {!subCat?.subCategories.length && (
                              <Tooltip title="Remove">
                                <Avatar
                                  className={classes.toolTip}
                                  sx={{ background: "#ba000d !important" }}
                                >
                                  <DeleteIcon
                                    fontSize="small"
                                    onClick={() => handleDelete(subCat._id)}
                                  />
                                </Avatar>
                              </Tooltip>
                            )}
                          </Stack>
                        </Stack>
                      </AccordionSummary>
                      {subCat?.subCategories.map((child) => {
                        return (
                          <AccordionDetails
                            key={child.name}
                            className={classes.accordionDetails}
                          >
                            <Accordion
                              disabled={
                                child?.subCategories.length ? false : true
                              }
                              className={classes.accordion}
                            >
                              <AccordionSummary
                                className={classes.accordionSummaryCss}
                                key={child.name}
                                expandIcon={
                                  child?.subCategories.length ? (
                                    <Avatar
                                      sx={{ backgroundColor: "#f39c12" }}
                                    >
                                      <ExpandMoreIcon />
                                    </Avatar>
                                  ) : null
                                }
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  width="100%"
                                  spacing={2}
                                >
                                  <Typography className={classes.name}>
                                    {child.name}
                                  </Typography>
                                  <Stack direction="row" spacing={1}>
                                    <Tooltip title="Edit">
                                      <Avatar className={classes.toolTip}>
                                        <EditIcon
                                          fontSize="small"
                                          onClick={() => handleEdit(child)}
                                        />
                                      </Avatar>
                                    </Tooltip>
                                    <Tooltip title="Remove">
                                      <Avatar
                                        className={classes.toolTip}
                                        sx={{
                                          background: "#ba000d !important",
                                        }}
                                      >
                                        <DeleteIcon
                                          fontSize="small"
                                          onClick={() =>
                                            handleDelete(child._id)
                                          }
                                        />
                                      </Avatar>
                                    </Tooltip>
                                  </Stack>
                                </Stack>
                              </AccordionSummary>
                            </Accordion>
                          </AccordionDetails>
                        );
                      })}
                    </Accordion>
                  </AccordionDetails>
                );
              })}
            </Accordion>
          );
        })
      ) : (
        <Accordion className={classes.accordion}>
          <AccordionSummary>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
              spacing={2}
            >
              <Typography className={classes.name}>
                {status === 'pending' ? "Loading..." : "Data not found"}
              </Typography>
            </Stack>
          </AccordionSummary>
        </Accordion>
      )}

      <AddCategory
        openModal={openModal}
        setOpenModal={setOpenModal}
        setIsEditable={setIsEditable}
        isEditable={isEditable}
        editData={editData}
        setEditData={setEditData}
        categoryStatus={status}
        data={renameKey(categories || [])}
      />

      <ConfirmationModal openModal={openModalConfirmation} removeStatus={removeStatus} handleClose={handleCloseConfirmationModal} id={deletedId} handleOk={dispatchDelete} content="Are you sure you want to Delete?" />

    </MainContainer>
  );
}
