import React, { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { Avatar, Button, DialogActions, DialogContent, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import ModalCommon from '../../../common/ModalCommon'
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Stack } from '@mui/system';
import EditVariant from './editVariant';
import AddQty from './addQty';
import useSellers from '../../../../redux/Seller/selector';
import useVariant from '../../../../redux/ProductVariants/selector';
import AddVariant from './addVariant';
import ConfirmationModal from '../../../common/ConfirmationModal';

const VariantModal = ({ openModal, setOpenModal, allVariants, product, productStatus }) => {
    const { fetchSellers, sellers } = useSellers()
    const { removeStatus, removeVariant, resetVariant } = useVariant()

    const [openAddVariant, setOpenAddVariant] = useState(false)
    const [openEditVariant, setOpenEditVariant] = useState(false)
    const [editableVariant, setEditableVariant] = useState({})
    const [openAddQty, setOpenAddQty] = useState(false)
    const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
    const [deletedId, setDeletedId] = useState();

    const handleCloseModal = () => {
        setOpenModal(false)
        resetVariant()
    }

    const handleOpenConfirmationModal = () => {
        setOpenModalConfirmation(true)
    }

    const handleCloseConfirmationModal = () => {
        setOpenModalConfirmation(false)
    }

    const handleDeleteVariant = (id) => {
        handleOpenConfirmationModal();
        setDeletedId(id)
    }

    const dispatchDelete = (id) => {
        removeVariant({ productVariantId: id })
    }

    const handleEditVariant = (id) => {
        setOpenEditVariant(true)
        const variant = allVariants.find((item) => item._id === id)
        setEditableVariant(variant)
    }

    const handleOpenAddQty = (id) => {
        fetchSellers()
        setOpenAddQty(true)
        const variant = allVariants.find((item) => item._id === id)
        setEditableVariant(variant)
    }

    const handleAddVariant = () => {
        setOpenAddVariant(true)
    }

    return (
        <>
            <ModalCommon
                open={openModal}
                handleClose={handleCloseModal}
                title="Variant"
                mxWidth="lg"
            >
                <DialogContent>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                        <Typography variant="subtitle1">Product Name : <b>{productStatus === "success" ? product[0]?.name : ""}</b> </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={handleAddVariant}
                            disabled={product[0]?.optionName1 === "" ? true : false}
                        >
                            Add Variant
                        </Button>
                    </Stack>
                    <Stack my={2} >
                        <Divider />
                    </Stack>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {productStatus === "pending"
                                ? <Stack alignItems="center"><CircularProgress size={30} /></Stack>
                                : <TableContainer>
                                    <Table sx={{ minWidth: 700 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                {product?.map((keyName, index) => {
                                                    return (
                                                        <>
                                                            {keyName.optionName1.length > 0 ? <TableCell>{keyName.optionName1}</TableCell> : null}
                                                            {keyName.optionName2.length > 0 ? <TableCell>{keyName.optionName2}</TableCell> : null}
                                                            {keyName.optionName3.length > 0 ? <TableCell>{keyName.optionName3}</TableCell> : null}
                                                            {keyName.optionName4.length > 0 ? <TableCell>{keyName.optionName4}</TableCell> : null}
                                                        </>
                                                    )
                                                })}
                                                <TableCell>Code</TableCell>
                                                <TableCell>Price</TableCell>
                                                <TableCell align='center'>Quality Class</TableCell>
                                                <TableCell align='center'>Qty Available</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allVariants?.map((variant, index) =>
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    {product[0]?.optionName1.length > 0 ? <TableCell>{variant.optionName1}</TableCell> : null}
                                                    {product[0]?.optionName2.length > 0 ? <TableCell>{variant.optionName2}</TableCell> : null}
                                                    {product[0]?.optionName3.length > 0 ? <TableCell>{variant.optionName3}</TableCell> : null}
                                                    {product[0]?.optionName4.length > 0 ? <TableCell>{variant.optionName4}</TableCell> : null}
                                                    <TableCell>{variant.code}</TableCell>
                                                    <TableCell>${variant.price}</TableCell>
                                                    <TableCell align='center'>{variant.qualityClass}</TableCell>
                                                    <TableCell align='center'>{variant.quantityAvailable}</TableCell>
                                                    <TableCell align="right" width={121}>
                                                        <Stack direction="row" spacing={1}>
                                                            <Tooltip title="Add Quantity" >
                                                                <Avatar
                                                                    sx={{
                                                                        background: "#f39c12",
                                                                        width: 35,
                                                                        height: 35,
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    <Button sx={{ color: "#fff", width: "100%", height: "100%" }} onClick={() => handleOpenAddQty(variant._id)}>
                                                                        <AddIcon fontSize="medium" />
                                                                    </Button>
                                                                </Avatar>
                                                            </Tooltip>
                                                            <Tooltip title="Edit Variant" >
                                                                <Avatar
                                                                    sx={{
                                                                        background: "#4267B2",
                                                                        width: 35,
                                                                        height: 35,
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    <Button sx={{ color: "#fff", width: "100%", height: "100%" }} onClick={() => handleEditVariant(variant._id)}>
                                                                        <EditIcon fontSize="small" />
                                                                    </Button>
                                                                </Avatar>
                                                            </Tooltip>
                                                            <Tooltip title="Delete Variant" >
                                                                <Avatar
                                                                    sx={{

                                                                        background: allVariants?.length === 1 ? "#dbdbdb" : "#ba000d",
                                                                        width: 35,
                                                                        height: 35,
                                                                        cursor: allVariants?.length === 1 ? "default" : "pointer",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        disabled={allVariants?.length === 1 ? true : false}
                                                                        sx={{ color: "#fff", width: "100%", height: "100%" }}
                                                                        onClick={() => handleDeleteVariant(variant._id)}>

                                                                        <DeleteIcon fontSize="small" />
                                                                    </Button>
                                                                </Avatar>
                                                            </Tooltip>
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Stack mt={2}>
                                <Divider />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    onClick={handleCloseModal}
                                >
                                    Done
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </ModalCommon >

            <AddVariant openModal={openAddVariant} setOpenModal={setOpenAddVariant} product={product} />

            <EditVariant openModal={openEditVariant} setOpenModal={setOpenEditVariant} variant={editableVariant} productData={product} />

            <AddQty openModal={openAddQty} setOpenModal={setOpenAddQty} variant={editableVariant} sellerData={sellers} />

            <ConfirmationModal openModal={openModalConfirmation} removeStatus={removeStatus} handleClose={handleCloseConfirmationModal} id={deletedId} handleOk={dispatchDelete} content="Are you sure you want to Delete?" />
        </>
    )
}

export default VariantModal