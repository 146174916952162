import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MainContainer from "../../common/MainContainer";
import DataTable from "../../common/DataTable";
import { Stack } from "@mui/system";
import Searchbar from "../../common/Searchbar";
import AddIcon from "@mui/icons-material/Add";
import AddUser from "./add";
import useUsers from "../../../redux/Users/selector";
import ConfirmationModal from "../../common/ConfirmationModal";
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from "file-saver";

export default function Users() {
  const { users, status, removeStatus, fetchUsers, fetchUser, remove } = useUsers()
  const allUsers = users.map((user) => ({
    ...user,
    name: user.firstName + " " + user.lastName,
  })).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const [openModal, setOpenModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState();
  const [deletedId, setDeletedId] = useState();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalConfirmation = () => {
    setOpenModalConfirmation(true);
  };
  const handleCloseModalConfirmation = () => {
    setOpenModalConfirmation(false);
  };

  useEffect(() => {
    if (status === "idle") {
      fetchUsers();
    }
  }, [fetchUsers, status]);

  const handleEditUser = (value) => {
    fetchUser(value)
    handleOpenModal();
    setIsEditable(true);
  };

  const handleDeleteUser = (id) => {
    handleOpenModalConfirmation()
    setDeletedId(id)
  }

  const handleDownloadDocumnet = (url) => {
    saveAs(url)
  }

  const dispatchDelete = (id) => {
    remove({ userId: id });
  }

  const handleSearch = (e) => {
    if (e.target.value) {
      const res = allUsers.filter((item) => {
        const inputTxt = (item.firstName + item.lastName + item.email).toLowerCase().trim().replace(/ /g, "")
        const text = (e.target.value).toLowerCase().trim().replace(/ /g, "")
        return inputTxt.includes(text)
      })
      setFilteredData(res)
    }
    else {
      setFilteredData(allUsers)
    }
  }

  useEffect(() => {
    setFilteredData(allUsers)
  }, [users])

  const columns = [
    { id: "name", label: "Name" },
    { id: "phone", label: "Phone" },
    { id: "email", label: "Email" },
    {
      id: "whatsappNumber",
      label: "Whatsapp",
    },
    { id: "address", label: "Address" },
    {
      id: "license", label: "License", jsx: (value) => {
        return <Tooltip title="Download License">
          <Avatar
            sx={{
              background: "#4267B2",
              width: 35,
              height: 35,
              cursor: "pointer",
            }}
          >
            <DownloadIcon
              onClick={() => handleDownloadDocumnet(value)}
              fontSize="small"
            />
          </Avatar>
        </Tooltip>
      }
    },
    {
      id: "businessRegLetter", label: "Registration Letter", jsx: (value) => {
        return <Tooltip title="Download Registration Letter">
          <Avatar
            sx={{
              background: "#4267B2",
              width: 35,
              height: 35,
              cursor: "pointer",
            }}
          >
            <DownloadIcon
              onClick={() => handleDownloadDocumnet(value)}
              fontSize="small"
            />
          </Avatar>
        </Tooltip>
      }
    },
    {
      id: "active",
      label: "Status",
      jsx: (value) =>
        value ? (
          <Typography color="green">Active</Typography>
        ) : (
          <Typography color="maroon">Inactive</Typography>
        ),
    },
    {
      id: "_id",
      label: "Actions",
      jsx: (value) => (
        <Stack direction="row" spacing={1}>
          <Tooltip title="Edit">
            <Avatar
              sx={{
                background: "#4267B2",
                width: 35,
                height: 35,
                cursor: "pointer",
              }}
            >
              <EditIcon
                onClick={() => handleEditUser(value)}
                fontSize="small"
              />
            </Avatar>
          </Tooltip>
          <Tooltip title="Remove" onClick={() => handleDeleteUser(value)}>
            <Avatar sx={{ background: "#ba000d", width: 35, height: 35, cursor: "pointer" }}>
              <DeleteIcon fontSize="small" />
            </Avatar>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <MainContainer title={"Users"}>
      <Stack direction="row" justifyContent="space-between" pb={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Searchbar handleSearch={handleSearch} />
        </Grid>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenModal}
        >
          Add User
        </Button>
      </Stack>
      <Divider />
      <DataTable
        columns={columns}
        rows={filteredData ?? allUsers}
        loading={status === "pending"}
      />

      <AddUser
        openModal={openModal}
        setOpenModal={setOpenModal}
        setIsEditable={setIsEditable}
        isEditable={isEditable}
      />

      <ConfirmationModal openModal={openModalConfirmation} removeStatus={removeStatus} handleClose={handleCloseModalConfirmation} id={deletedId} handleOk={dispatchDelete} content="Are you sure you want to Delete?" />
    </MainContainer>
  );
}
