import React, { useEffect, useState } from "react";
import { Avatar, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import ModalCommon from "../../../common/ModalCommon";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import useProducts from "../../../../redux/Product/selector";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import * as Yup from "yup";
import UploadImageField from "../../../common/UploadImageField";
import useVariant from "../../../../redux/ProductVariants/selector";

const AddProducts = ({
    setOpenModal,
    openModal,
    setIsEditable,
    isEditable,
    data,
    variantData,
    resetVariant,
    resetProduct,
    categories,
    productStatus,
    variantStatus,
}) => {
    const { createStatus, updateStatus, create, update, updateVariant } = useProducts();
    const { variant, fetchVariant } = useVariant();
    const [isAddVariant, setIsAddVariant] = useState(false)

    const loadingBoolean =
        (createStatus === "pending" ? true : false) ||
        (updateStatus === "pending" ? true : false) ||
        (isEditable && data && !Object.keys(data).length ? true : false);

    let allCategoryData = subCategoryFn(categories)
    function subCategoryFn(data) {
        var result = [];
        data?.forEach(function (a) {
            result.push(a);
            if (Array.isArray(a.subCategories) && a.subCategories.length) {
                result = result.concat(subCategoryFn(a.subCategories));
            }
        });
        const filteredResult = result.filter((x) => x.subCategories.length == 0)

        return filteredResult;
    }

    const [optionNameData, setOptionNameData] = useState([{ optionName: "" }])

    let defaultOptField = {}
    optionNameData?.filter((item) => item?.optionName)?.forEach((opt, index) => defaultOptField[`optionName${index + 1}`] = Yup.string().required("Option Name is required"))

    const priceRegex = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/

    const addProductSchema = Yup.object().shape({
        categoryId: Yup.string().required("Category is required"),
        name: Yup.string().required('Name is required'),
        description: Yup.string().required("Product description is required"),
        hasVariants: Yup.boolean().required("Variants is required"),
        image: Yup.string().required("Image is required"),
        productCode: Yup.string().required('Product code is required'),
        productPrice: Yup.string().matches(priceRegex, "Price is not valid").when("hasVariants", {
            is: false && !isEditable,
            then: Yup.string().required('Product price is required'),
        }),
        qualityClass: Yup.string().when("hasVariants", {
            is: false && !isEditable,
            then: Yup.string().required("QualityClass is required"),
        }),
        option: Yup.array().when("hasVariants", {
            is: true || "true",
            then: Yup.array().of(
                Yup.object().shape({
                    optionName: Yup.string().ensure()
                        .required("Option Name is required"),
                }).required("Please add Option")
            ),
        }),
        variants: Yup.array().when("hasVariants", {
            is: true || "true",
            then: Yup.array().of(
                Yup.object().shape({
                    ...defaultOptField,
                    price: !isEditable && Yup.string().matches(priceRegex, "Price is not valid").required("Price is required"),
                    code: !isEditable && Yup.string().required("Code is required"),
                    qualityClass: !isEditable && Yup.string().required("Quality class is required"),
                }).required("Variant is required"),
            ),
        }),
    });

    const checkVariantAvailibility = (key) => {
        const response = variant.find((x) => x[key])
        if (response) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        const dataToReturn = []
        if (data && isEditable) {
            let variantOptions = {}
            Object.keys(data).map((item) => {
                if (item.includes("optionName")) {
                    dataToReturn.push({ optionName: data[`${item}`] })
                    if (data[`${item}`]) {
                        variantOptions[`${item}`] = data[`${item}`]
                    }
                }
                return dataToReturn
            })
            setOptionNameData(dataToReturn.filter((x) => x?.optionName !== ""))
        }
    }, [data, isEditable])

    const formik = useFormik({
        initialValues: {
            categoryId: isEditable && data ? data.categoryId._id : "",
            productId: isEditable && data ? data._id : "",
            name: isEditable && data ? data.name : "",
            description: isEditable && data ? data.description : "",
            code: isEditable && data ? data.code : "",
            productCode: isEditable && data ? data.code : "",
            image: isEditable && data ? data.imageUrl : "",
            hasVariants: isEditable && data ? data.hasVariants : "false",
            productPrice: isEditable && data ? data.variants[0]?.price : "",
            qualityClass: isEditable && data ? data.variants[0]?.qualityClass : "",
            option: isEditable && data ? optionNameData : [{ optionName: "" }],
            variants: isEditable && data
                ? [Object.assign(...optionNameData.map((item, index) => { return { [`optionName${index + 1}`]: item['optionName'] } }), { code: "", price: "" })]
                : [{ code: "", price: "" }]

        },
        enableReinitialize: true,
        validationSchema: addProductSchema,
        onSubmit: (values) => {
            let formData = new FormData();
            let UpdateProductPayload = new FormData();
            let UpdateVariantPayload = new FormData();

            if (isEditable) {
                if (!values.hasVariants || values.hasVariants === "false") {
                    UpdateProductPayload.set('productId', data._id ?? "")
                    UpdateProductPayload.set('name', values.name ?? "");
                    UpdateProductPayload.set('code', values.productCode ?? "");
                    UpdateProductPayload.set('optionName1', "");
                    UpdateProductPayload.set('hasVariants', values.hasVariants ?? false);
                    UpdateProductPayload.set('description', values.description ?? "");
                    if (values?.image) {
                        UpdateProductPayload.append('image', values.image ?? "");
                    }

                    UpdateVariantPayload.set('productVariantId', variantData._id ?? "")
                    UpdateVariantPayload.set('optionName1', "");
                    UpdateVariantPayload.set("code", values.productCode ?? "");
                    UpdateVariantPayload.set("price", values.productPrice ?? "");
                    UpdateVariantPayload.set("qualityClass", values.qualityClass ?? "");
                }
                else {
                    UpdateProductPayload.set('productId', data._id ?? "")
                    UpdateProductPayload.set('name', values.name ?? "");
                    UpdateProductPayload.set('code', values.productCode ?? "");
                    UpdateProductPayload.set('hasVariants', values.hasVariants ?? false);
                    UpdateProductPayload.set('description', values.description ?? "");
                    if (values?.image) {
                        UpdateProductPayload.append('image', values.image ?? "");
                    }
                    UpdateProductPayload.set(`optionName1`, optionNameData[0]?.optionName ? optionNameData[0]?.optionName : "")
                    UpdateProductPayload.set(`optionName2`, optionNameData[1]?.optionName ? optionNameData[1]?.optionName : "")
                    UpdateProductPayload.set(`optionName3`, optionNameData[2]?.optionName ? optionNameData[2]?.optionName : "")
                    UpdateProductPayload.set(`optionName4`, optionNameData[3]?.optionName ? optionNameData[3]?.optionName : "")

                }
            } else {
                formData.set('name', values.name ?? "");
                formData.set('code', values.productCode ?? "");
                formData.set('description', values.description ?? "");
                formData.set('hasVariants', values.hasVariants ?? false);
                if (values?.image) {
                    formData.append('image', values.image ?? "");
                }
                formData.set('categoryId', values.categoryId ?? "")
                formData.set('optionName1', "")
                formData.set("variants[0][code]", values.productCode ?? "");
                formData.set("variants[0][price]", values.productPrice ?? "");
                formData.set("variants[0][qualityClass]", values.qualityClass ?? "");
                if (values.hasVariants === "true") {
                    values.option.forEach((item, index) =>
                        formData.set(`optionName${index + 1}`, item.optionName)
                    )
                    values.variants.forEach((item, index) => {
                        formData.set(`variants[${index}][code]`, item.code ?? "")
                        formData.set(`variants[${index}][price]`, item.price ?? "")
                        formData.set(`variants[${index}][optionName1]`, item.optionName1 ?? "")
                        formData.set(`variants[${index}][optionName2]`, item.optionName2 ?? "")
                        formData.set(`variants[${index}][optionName3]`, item.optionName3 ?? "")
                        formData.set(`variants[${index}][optionName4]`, item.optionName4 ?? "")
                        if (item.qualityClass) {
                            formData.set(`variants[${index}][qualityClass]`, item.qualityClass ?? "")
                        }
                    })
                }
            }
            handleSubmitData(formData, UpdateProductPayload, UpdateVariantPayload);
        },
    });

    const handleSubmitData = (formData, UpdateProductPayload, UpdateVariantPayload) => {
        if (formik.errors.image) {
            formik.setTouched({ ...formik.touched, image: true })
        }
        if (isEditable) {
            if (formik.values.hasVariants || formik.values.hasVariants === "true") {
                update(UpdateProductPayload)
            }
            else {
                const isToast = false
                update(UpdateProductPayload)
                updateVariant(UpdateVariantPayload, isToast)
            }
        } else {
            create(formData)
        }
    };

    const handleCloseModal = (e) => {
        setOpenModal(false)
        setIsEditable(false)
        setIsAddVariant(false)
        setOptionNameData([{ optionName: "" }])
        resetVariant()
        resetProduct()
        formik.resetForm()
    };

    const handleImageUploadChange = (e) => {
        if (e.target.files[0]) {
            formik.setFieldValue("image", e.target.files[0])
        }
    }

    const handleAddOption = () => {
        setOptionNameData([...optionNameData, { optionName: "" }])
    }

    const handleDeleteOption = (index) => {
        const removeValues = optionNameData.filter((item, i) => i !== index)
        setOptionNameData(removeValues)
        if (!isEditable) {
            handleAddVariant(removeValues)
        }
    }

    const handleOptionNameChange = (e, index) => {
        const variantDataClone = [...optionNameData]
        variantDataClone[index][e.target.name] = e.target.value;
        setOptionNameData(variantDataClone)
    }

    const onVariantChange = (e, index) => {
        const variantsClone = [...formik.values.variants];
        variantsClone[index][e.target.name] = e.target.value;
        formik.setFieldValue("variants", variantsClone)
    }

    const variantOptionVal = (item) => {
        let optVal = formik.values.option?.filter((item) => item.optionName)
        let variantData = {};
        optVal?.forEach((opt, index) => variantData[`optionName${index + 1}`] = item[`optionName${index + 1}`] ?? "")
        return variantData
    }

    const handleAddVariant = (optionsArray) => {
        const options = optionsArray && optionsArray.length > 0 ? optionsArray : optionNameData
        // Add variant button logic
        setIsAddVariant(true)
        formik.setFieldValue("option", options)
        const newVariants = formik.values.variants.map(item =>
            Object.assign({
                price: item.price ?? "",
                code: item.code ?? "",
                qualityClass: item.qualityClass ?? "",
            },
                variantOptionVal(item)
            )
        )
        formik.setFieldValue("variants", newVariants)
    }

    const handleAddVariantRow = (rowData) => {
        let variantData = {};
        formik.values.option?.filter((item) => item?.optionName)?.forEach((opt, index) => variantData[`optionName${index + 1}`] = "")
        const newItem = {
            ...variantData, price: "", code: "", qualityClass: "",
        };
        const newRow = [...formik.values.variants, newItem];
        formik.setFieldValue("variants", newRow)
    }

    const handleRemoveVariantRow = (index) => {
        const deleteRow = formik.values.variants.filter((ele, i) => i !== index)
        formik.setFieldValue("variants", deleteRow)
    }

    useEffect(() => {
        if ((createStatus === "success") || (updateStatus === "success")) {
            handleCloseModal();
        }
    }, [createStatus, updateStatus])

    useEffect(() => {
        if (data) {
            fetchVariant(data._id)
        }
    }, [data])

    useEffect(() => {
        if (formik.values.hasVariants) {
            formik.setFieldTouched("option", false)
            formik.setFieldTouched("variants", false)
        }
        if (formik.values.hasVariants === "false" || false) {
            formik.setFieldTouched("productPrice", false)
            formik.setFieldTouched("qualityClass", false)
        }
    }, [formik.values.hasVariants])

    return (
        <ModalCommon
            open={openModal}
            handleClose={handleCloseModal}
            title={isEditable ? "Edit Product" : "Add Product"}
            mxWidth="lg"
        >
            <DialogContent>
                {productStatus === "pending" && variantStatus === "pending"
                    ? "Loading..."
                    : <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth disabled={isEditable ? true : false}>
                                            <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                            <Select
                                                defaultValue=""
                                                label="Category"
                                                name="categoryId"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                disabled={loadingBoolean}
                                                onChange={formik.handleChange}
                                                value={formik.values.categoryId}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
                                                helperText={formik.touched.categoryId && formik.errors.categoryId}
                                            >
                                                {allCategoryData?.map((cat) => {
                                                    return <MenuItem key={cat._id} value={cat._id}>{cat.name}</MenuItem>
                                                })}
                                            </Select>
                                            <Stack color={"#d32f2f"} fontSize="12px" ml={1.8}>
                                                {formik.errors.categoryId && formik.touched.categoryId
                                                    ? formik.errors.categoryId
                                                    : null}
                                            </Stack>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Product Name"
                                            variant="outlined"
                                            name="name"
                                            type="text"
                                            disabled={loadingBoolean}
                                            onChange={formik.handleChange}
                                            value={formik.values.name}
                                            fullWidth
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched.name &&
                                                Boolean(formik.errors.name)
                                            }
                                            helperText={
                                                formik.errors.name && formik.touched.name
                                                    ? formik.errors.name
                                                    : null
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Product Code"
                                            variant="outlined"
                                            name="productCode"
                                            type="text"
                                            disabled={loadingBoolean}
                                            value={formik.values.productCode}
                                            fullWidth
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.productCode &&
                                                Boolean(formik.errors.productCode)
                                            }
                                            helperText={
                                                formik.errors.productCode && formik.touched.productCode
                                                    ? formik.errors.productCode
                                                    : null
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Product Description"
                                            variant="outlined"
                                            name="description"
                                            type="text"
                                            disabled={loadingBoolean}
                                            onChange={formik.handleChange}
                                            value={formik.values.description}
                                            fullWidth
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched.description &&
                                                Boolean(formik.errors.description)
                                            }
                                            helperText={
                                                formik.errors.description && formik.touched.description
                                                    ? formik.errors.description
                                                    : null
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <UploadImageField
                                    onChange={handleImageUploadChange}
                                    value={formik.values.image}
                                    name="ProductImages"
                                    error={formik.errors.image && formik.touched.image ? formik.errors.image : null}
                                    placeholder="Add Images"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel id="demo-controlled-radio-buttons-group">Has Variants</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="hasVariants"
                                        value={formik.values.hasVariants}
                                        onChange={formik.handleChange}
                                        sx={{ flexDirection: "row" }}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="Yes" disabled={isEditable ? true : false} />
                                        <FormControlLabel value={false} control={<Radio />} label="No" disabled={isEditable ? true : false} />
                                    </RadioGroup>
                                    {formik.touched.hasVariants && formik.errors.hasVariants ?
                                        <Stack color={"#d32f2f"} fontSize="12px" ml={1.8}>
                                            {formik.errors.hasVariants}
                                        </Stack> : null
                                    }
                                </FormControl>
                            </Grid>
                            {!formik.values.hasVariants || formik.values.hasVariants === "false" ?
                                <>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Product Price"
                                            variant="outlined"
                                            name="productPrice"
                                            type="text"
                                            disabled={loadingBoolean}
                                            onChange={formik.handleChange}
                                            value={formik.values.productPrice}
                                            fullWidth
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched.productPrice &&
                                                Boolean(formik.errors.productPrice)
                                            }
                                            helperText={
                                                formik.errors.productPrice && formik.touched.productPrice
                                                    ? formik.errors.productPrice
                                                    : null
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Quality Class</InputLabel>
                                            <Select
                                                defaultValue=""
                                                name="qualityClass"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Quality Class"
                                                disabled={loadingBoolean}
                                                onChange={formik.handleChange}
                                                value={formik.values.qualityClass}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.qualityClass && Boolean(formik.errors.qualityClass)}
                                                helperText={formik.touched.qualityClass && formik.errors.qualityClass}
                                            >
                                                <MenuItem value="A">A</MenuItem>
                                                <MenuItem value="B">B</MenuItem>
                                                <MenuItem value="C">C</MenuItem>
                                            </Select>
                                            <Stack color={"#d32f2f"} fontSize="12px" ml={1.8} mt={0.5}>
                                                {formik.errors.qualityClass && formik.touched.qualityClass
                                                    ? formik.errors.qualityClass
                                                    : null}
                                            </Stack>
                                        </FormControl>
                                    </Grid>
                                </> :
                                <>
                                    {optionNameData?.map((res, index) => {
                                        return (
                                            <>
                                                <Grid item xs={12} sx={{ display: "flex", alignItems: "center", gridGap: "16px" }} key={index}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="Option Name"
                                                        variant="outlined"
                                                        name={"optionName"}
                                                        type="text"
                                                        fullWidth
                                                        value={res.optionName}
                                                        onChange={(e) => {
                                                            handleOptionNameChange(e, index)
                                                            if (!isEditable) {
                                                                handleAddVariant()
                                                            }
                                                        }}
                                                        disabled={(checkVariantAvailibility(`optionName${index + 1}`) || loadingBoolean) ? true : false}
                                                    />
                                                    {index === 0 ?
                                                        <Tooltip title="Add Option" >
                                                            <Avatar
                                                                sx={{
                                                                    background: optionNameData.length === 4 ? "#dbdbdb" : "#4267B2",
                                                                    width: 45,
                                                                    height: 45,
                                                                    cursor: optionNameData.length === 4 ? "default" : "pointer",
                                                                }}
                                                            >
                                                                <Button sx={{ color: "#fff", width: "100%", height: "100%" }} disabled={optionNameData.length === 4 ? true : false} onClick={handleAddOption}>
                                                                    <AddIcon fontSize="medium" />
                                                                </Button>
                                                            </Avatar>
                                                        </Tooltip> :
                                                        <Tooltip title="Delete Option">
                                                            <Avatar
                                                                sx={{
                                                                    background: "#ba000d",
                                                                    width: 45,
                                                                    height: 45,
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <Button
                                                                    sx={{ color: "#fff", width: "100%", height: "100%" }}
                                                                    onClick={() => handleDeleteOption(index)}
                                                                    disabled={checkVariantAvailibility(`optionName${index + 1}`)}
                                                                >
                                                                    <RemoveIcon fontSize="medium" />
                                                                </Button>
                                                            </Avatar>
                                                        </Tooltip>
                                                    }
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {!res.optionName.length && formik.errors.option &&
                                                        formik.touched.option && (
                                                            <Stack color={"#d32f2f"} fontSize="12px" ml={1.8}>
                                                                {formik.errors.option.at(-1).optionName}
                                                            </Stack>
                                                        )}
                                                </Grid>
                                            </>
                                        )
                                    })}
                                </>
                            }

                            {/* {formik.values.hasVariants && formik.values.hasVariants === "true" && !isEditable &&
                                <Grid item xs={12}>
                                    <DialogActions onClick={handleAddVariant} sx={{ padding: 0, marginBottom: 2 }}>
                                        <LoadingButton
                                            type="button"
                                            variant="contained"
                                            fullWidth
                                            sx={{ height: "46px" }}
                                        >
                                            Add Variants
                                        </LoadingButton>
                                    </DialogActions>
                                </Grid>
                            } */}

                            {/* ===== if has variant Start ===== */}
                            {(formik.values.hasVariants === "true" || formik.values.hasVariants === true) && isAddVariant && optionNameData[0]?.hasOwnProperty("optionName") &&
                                <>
                                    {formik.values.option &&
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        {formik.values.option.map((item, index) => {
                                                            return item.optionName !== "" && <TableCell key={index}>{item.optionName}</TableCell>
                                                        })}
                                                        <TableCell>Code</TableCell>
                                                        <TableCell>Price</TableCell>
                                                        <TableCell>Quality Class</TableCell>
                                                        <TableCell>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {formik.values.variants.map((variantItem, varIndex) => {
                                                        return <TableRow key={varIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            {formik.values.option.map((item, index) => {
                                                                return item.optionName !== "" &&
                                                                    <TableCell key={index} component="th" scope="row" sx={{ minWidth: "145px" }}>
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            label={item.optionName}
                                                                            variant="outlined"
                                                                            name={`optionName${index + 1}`}
                                                                            value={item[`optionName${index + 1}`]}
                                                                            fullWidth
                                                                            disabled={loadingBoolean}
                                                                            onChange={(e) => onVariantChange(e, varIndex)}
                                                                            error={Boolean(formik.errors?.variants?.[varIndex]?.[`optionName${index + 1}`]) && formik.touched && formik.touched?.variants?.[varIndex]?.[`optionName${index + 1}`]}
                                                                            helperText={formik.touched && formik.touched?.variants?.[varIndex]?.[`optionName${index + 1}`] && formik.errors?.variants?.[varIndex]?.[`optionName${index + 1}`]}
                                                                        />
                                                                    </TableCell>
                                                            })}

                                                            <TableCell component="th" scope="row" sx={{ minWidth: "145px" }}>
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    label="Code"
                                                                    variant="outlined"
                                                                    name="code"
                                                                    value={variantItem.code}
                                                                    fullWidth
                                                                    disabled={loadingBoolean}
                                                                    onChange={(e) => onVariantChange(e, varIndex)}
                                                                    error={formik.touched?.variants?.length && Boolean(formik.errors?.variants?.[varIndex]?.code)}
                                                                    helperText={formik.touched?.variants?.length && formik.errors?.variants?.[varIndex]?.code}
                                                                    onBlur={formik.handleBlur}
                                                                />
                                                            </TableCell>
                                                            <TableCell sx={{ minWidth: "145px" }}>
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    label="Price"
                                                                    variant="outlined"
                                                                    name="price"
                                                                    onChange={(e) => onVariantChange(e, varIndex)}
                                                                    value={variantItem.price}
                                                                    fullWidth
                                                                    disabled={loadingBoolean}
                                                                    error={formik.touched?.variants?.length && Boolean(formik.errors?.variants?.[varIndex]?.price)}
                                                                    helperText={formik.touched?.variants?.length && formik.errors?.variants?.[varIndex]?.price}
                                                                    onBlur={formik.handleBlur}
                                                                />
                                                            </TableCell>
                                                            <TableCell sx={{ minWidth: "175px" }}>
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="demo-simple-select-label">Quality Class</InputLabel>
                                                                    <Select
                                                                        defaultValue=""
                                                                        name="qualityClass"
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        label="Quality Class"
                                                                        disabled={loadingBoolean}
                                                                        onChange={(e) => onVariantChange(e, varIndex)}
                                                                        value={variantItem.qualityClass}
                                                                        error={formik.touched?.variants?.[varIndex]?.qualityClass && Boolean(formik.errors?.variants?.[varIndex]?.qualityClass)}
                                                                        helperText={formik.touched?.variants?.[varIndex]?.qualityClass && Boolean(formik.errors?.variants?.[varIndex]?.qualityClass)}
                                                                    >
                                                                        <MenuItem value="A">A</MenuItem>
                                                                        <MenuItem value="B">B</MenuItem>
                                                                        <MenuItem value="C">C</MenuItem>
                                                                    </Select>
                                                                    <Stack color={"#d32f2f"} fontSize="12px" ml={1.8} mt={0.5}>
                                                                        {formik.touched?.variants?.[varIndex]?.qualityClass && Boolean(formik.errors?.variants?.[varIndex]?.qualityClass)
                                                                            ? formik.errors.variants?.[varIndex]?.qualityClass
                                                                            : null}
                                                                    </Stack>
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {varIndex === 0 ?
                                                                    <Tooltip title="Add Row" disabled={loadingBoolean}>
                                                                        <Avatar
                                                                            sx={{
                                                                                background: "#4267B2",
                                                                                width: 45,
                                                                                height: 45,
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            <Button onClick={() => handleAddVariantRow(variantItem)} sx={{ color: "#fff", width: "100%", height: "100%" }}>
                                                                                <AddIcon fontSize="medium" />
                                                                            </Button>
                                                                        </Avatar>
                                                                    </Tooltip>
                                                                    :
                                                                    <Tooltip title="Delete Row" disabled={loadingBoolean}>
                                                                        <Avatar
                                                                            sx={{
                                                                                background: "#ba000d",
                                                                                width: 45,
                                                                                height: 45,
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            <Button onClick={() => handleRemoveVariantRow(varIndex)} sx={{ color: "#fff", width: "100%", height: "100%" }}>
                                                                                <RemoveIcon fontSize="medium" />
                                                                            </Button>
                                                                        </Avatar>
                                                                    </Tooltip>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    }
                                </>
                            }
                            {/* ===== if has variant END===== */}
                            <Grid item xs={12}>
                                <Stack mt={2}>
                                    <Divider />
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <DialogActions>
                                    <LoadingButton
                                        type="submit"
                                        loading={createStatus === "pending" || updateStatus === "pending" ? true : false}
                                        variant="contained"
                                    >
                                        Save
                                    </LoadingButton>
                                    <Button
                                        variant="outlined"
                                        size="medium"
                                        onClick={handleCloseModal}
                                    >
                                        Cancel
                                    </Button>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    </form>
                }
            </DialogContent>
        </ModalCommon>
    );
}

export default AddProducts;
