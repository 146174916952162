import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiGet, ApiPost, ApiPut } from "../../component/api";

export const fetchAllConfig = createAsyncThunk(
  "config/fetchAllConfig",
  async (data, thunkAPI) => {
    try {
      const result = await ApiGet("auth/admin/config/all");
      if (result) {
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createConfig = createAsyncThunk(
  "config/createConfig",
  async (data, thunkAPI) => {
    try {
      const result = await ApiPost("auth/admin/config", data, {
        isFormData: true,
      });
      if (result) {
        toast.success(result.message, { autoClose: 3000 });
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateConfig = createAsyncThunk(
  "config/updateConfig",
  async (data, thunkAPI) => {
    try {
      const result = await ApiPut("auth/admin/config", data, {
        isFormData: true,
      });
      if (result) {
        toast.success(result.message, { autoClose: 3000 });
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const settingSlice = createSlice({
  name: "setting",
  initialState: {
    status: "idle",
    createStatus: "idle",
    updateStatus: "idle",
    allConfig: [],
  },
  reducers: {
    resetSettings(state) {
      state.updateStatus = "idle";
      state.createStatus = "idle";
    },
  },
  extraReducers: {
    // fetch all users
    [fetchAllConfig.pending]: (state) => {
      state.status = "pending";
    },
    [fetchAllConfig.fulfilled]: (state, action) => {
      state.status = "success";
      state.allConfig = action.payload;
    },
    [fetchAllConfig.rejected]: (state) => {
      state.status = "failed";
    },

    // create Config
    [createConfig.pending]: (state) => {
      state.createStatus = "pending";
    },
    [createConfig.fulfilled]: (state, action) => {
      state.createStatus = "success";
      state.allConfig = [...state.allConfig, action.payload];
    },
    [createConfig.rejected]: (state) => {
      state.createStatus = "failed";
    },

    // update Config
    [updateConfig.pending]: (state) => {
      state.updateStatus = "pending";
    },
    [updateConfig.fulfilled]: (state, action) => {
      state.updateStatus = "success";
      const findIndex = state.allConfig.findIndex((item) => {
        return item._id === action.payload._id
      })
      state.allConfig[findIndex] = action.payload
    },
    [updateConfig.rejected]: (state) => {
      state.updateStatus = "failed";
    },
  },
});

export const { resetSettings } = settingSlice.actions;

export default settingSlice.reducer;
