import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiGet, ApiPost } from "../../component/api";

export const fetchAllUsers = createAsyncThunk(
  "category/fetchAllUsers",
  async (data, thunkAPI) => {
    try {
      const result = await ApiGet("user/all");
      if (result) {
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchSingleUser = createAsyncThunk(
  "user/fetchSingleUser",
  async (id, thunkAPI) => {
    try {
      const result = await ApiGet(`user/${id}`);
      if (result) {
        return thunkAPI.fulfillWithValue(result.data[0]);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createUser = createAsyncThunk(
  "user/createUser",
  async (userData, thunkAPI) => {
    try {
      const result = await ApiPost("user/create", userData,  {
        isFormData: true,
      });
      if (result) {
        toast.success("User added successfully", { autoClose: 3000 });
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (userData, thunkAPI) => {
    try {
      const result = await ApiPost("user/update", userData, {
        headers: {
          'content-type': 'multipart/form-data',
          "Authorization": "Bearer " + localStorage.getItem("token")
        },
      });
      if (result) {
        toast.success(result.message, { autoClose: 3000 });
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const removeUser = createAsyncThunk(
  "user/removeUser",
  async (id, thunkAPI) => {
    try {
      const result = await ApiPost("user/delete", id);
      if (result) {
        toast.success(result.message, { autoClose: 3000 });
        return thunkAPI.fulfillWithValue(id);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    status: "idle",
    createStatus: "idle",
    updateStatus: "idle",
    removeStatus: "idle",
    users: [],
    user: {},
  },
  reducers: {
    resetUser(state) {
      state.updateStatus = "idle";
      state.createStatus = "idel"
      state.user = {}
    },
  },
  extraReducers: {
    // fetch all users
    [fetchAllUsers.pending]: (state) => {
      state.status = "pending";
    },
    [fetchAllUsers.fulfilled]: (state, action) => {
      state.status = "success";
      state.users = action.payload;
    },
    [fetchAllUsers.rejected]: (state) => {
      state.status = "failed";
    },
    // fetch single users
    [fetchSingleUser.pending]: (state) => {
      state.status = "pending";
    },
    [fetchSingleUser.fulfilled]: (state, action) => {
      state.status = "success";
      state.user = action.payload;
    },
    [fetchSingleUser.rejected]: (state) => {
      state.status = "failed";
    },
    // create new user
    [createUser.pending]: (state) => {
      state.createStatus = "pending";
    },
    [createUser.fulfilled]: (state, action) => {
      state.createStatus = "success";
      state.users = [...state.users, action.payload];
    },
    [createUser.rejected]: (state) => {
      state.createStatus = "failed";
    },
    // update user
    [updateUser.pending]: (state) => {
      state.updateStatus = "pending";
    },
    [updateUser.fulfilled]: (state, action) => {
      state.updateStatus = "success";
      const findIndex = state.users?.findIndex(
        (user) => user._id === action.payload._id
      );
      if (findIndex !== -1) {
        state.users[findIndex] = action.payload;
      }
    },
    [updateUser.rejected]: (state) => {
      state.updateStatus = "failed";
    },
    // remove user
    [removeUser.pending]: (state) => {
      state.removeStatus = "pending";
    },
    [removeUser.fulfilled]: (state, action) => {
      state.removeStatus = "success";
      state.users = state.users.filter(
        (user) => user._id !== action.payload.userId
      );
    },
    [removeUser.rejected]: (state) => {
      state.removeStatus = "failed";
    },
  },
});

export const { resetUser } = usersSlice.actions;

export default usersSlice.reducer;
