import { makeStyles } from "@material-ui/styles";

const useStyleMainDisplay = makeStyles((theme) => ({
  setdisplay:{
    display:'flex',
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  setsidebaricon: {
    color: "#b8c7ce",
    "&:hover": {
      color: "white",
    },
  },
  setmenusetting: {
    "&:hover": {
      color: "white",
    },
  },
  setwebdisplay: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  setmobileview: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  customBadgemail: {
    backgroundColor: "#00a65a",
    color: "white",
  },
  custombadgeicon: {
    color: "white",
    "&:hover": {
      color: "rgb(31, 38, 31,0.50)",
    },
  },
  customBadgenoty: {
    backgroundColor: "#f39c12",
    color: "white",
  },
  seticonbtn:{
    padding:"12px !important",
    [theme.breakpoints.down("md")]: {
      padding:'6px !important'
    },
  }
}));

export default useStyleMainDisplay;