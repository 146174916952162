import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import ModalCommon from '../../../../common/ModalCommon'
import useVariant from '../../../../../redux/ProductVariants/selector'
import * as Yup from "yup";

const EditVariant = ({ openModal, setOpenModal, variant, productData }) => {
    const { updateStatus, updateVariant, resetVariant } = useVariant()
    const product = productData[0]

    const loadingBoolean = 
    (updateStatus === "pending" ? true : false) ||
    (!Object.keys(variant).length ? true : false);

    const editVariantSchema = Yup.object().shape({
        optionName1: product?.optionName1 && Yup.string().required("Option name is required"),
        optionName2: product?.optionName2 && Yup.string().required("Option name is required"),
        optionName3: product?.optionName3 && Yup.string().required("Option name is required"),
        optionName4: product?.optionName4 && Yup.string().required("Option name is required"),
        code: Yup.string().required("Code is Required"),
        price: Yup.string().required("price is Required"),
        qualityClass: Yup.string().required("qualityClass is Required"),
    });

    const formik = useFormik({
        initialValues: {
            productVariantId: variant?._id ?? "",
            quantityAvailable: variant?.quantityAvailable ?? "",
            code: variant?.code ?? "",
            price: variant?.price ?? "",
            qualityClass: variant?.qualityClass ?? "",
            optionName1: variant?.optionName1 ?? "",
            optionName2: variant?.optionName2 ?? "",
            optionName3: variant?.optionName3 ?? "",
            optionName4: variant?.optionName4 ?? "",
        },
        enableReinitialize: true,
        validationSchema: editVariantSchema,
        onSubmit: (values) => {
            updateProductVariant(values)
        },
    })

    const updateProductVariant = (values) => {
        updateVariant(values)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        formik.resetForm()
        resetVariant()
    }

    useEffect(() => {
        if (updateStatus === "success") {
            handleCloseModal()
        }
    }, [updateStatus])

    return (
        <ModalCommon
            open={openModal}
            handleClose={handleCloseModal}
            title="Edit Variant"
            mxWidth="sm"
        >
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        {product?.optionName1 !== "" &&
                            <Grid item xs={6}>
                                <TextField
                                    id="outlined-basic"
                                    label={product?.optionName1}
                                    variant="outlined"
                                    name="optionName1"
                                    type="text"
                                    fullWidth
                                    disabled={loadingBoolean}
                                    onChange={formik.handleChange}
                                    value={formik.values.optionName1}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.optionName1 && Boolean(formik.errors.optionName1)}
                                    helperText={formik.errors.optionName1 && formik.touched.optionName1 ? formik.errors.optionName1 : null}
                                />

                            </Grid>
                        }
                        {product?.optionName2 !== "" &&
                            <Grid item xs={6}>
                                <TextField
                                    id="outlined-basic"
                                    label={product?.optionName2}
                                    variant="outlined"
                                    name="optionName2"
                                    type="text"
                                    fullWidth
                                    disabled={loadingBoolean}
                                    onChange={formik.handleChange}
                                    value={formik.values.optionName2}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.optionName2 && Boolean(formik.errors.optionName2)}
                                    helperText={formik.errors.optionName2 && formik.touched.optionName2 ? formik.errors.optionName2 : null}
                                />
                            </Grid>
                        }
                        {product?.optionName3 !== "" &&
                            <Grid item xs={6}>
                                <TextField
                                    id="outlined-basic"
                                    label={product?.optionName3}
                                    variant="outlined"
                                    name="optionName3"
                                    type="text"
                                    fullWidth
                                    disabled={loadingBoolean}
                                    onChange={formik.handleChange}
                                    value={formik.values.optionName3}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.optionName3 && Boolean(formik.errors.optionName3)}
                                    helperText={formik.errors.optionName3 && formik.touched.optionName3 ? formik.errors.optionName3 : null}
                                />
                            </Grid>
                        }
                        {product?.optionName4 !== "" &&
                            <Grid item xs={6}>
                                <TextField
                                    id="outlined-basic"
                                    label={product?.optionName4}
                                    variant="outlined"
                                    name="optionName4"
                                    type="text"
                                    fullWidth
                                    disabled={loadingBoolean}
                                    onChange={formik.handleChange}
                                    value={formik.values.optionName4}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.optionName4 && Boolean(formik.errors.optionName4)}
                                    helperText={formik.errors.optionName4 && formik.touched.optionName4 ? formik.errors.optionName4 : null}
                                />
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <TextField
                                id="outlined-basic"
                                label="Code"
                                variant="outlined"
                                name="code"
                                type="text"
                                fullWidth
                                disabled={loadingBoolean}
                                onChange={formik.handleChange}
                                value={formik.values.code}
                                onBlur={formik.handleBlur}
                                error={formik.touched.code && Boolean(formik.errors.code)}
                                helperText={formik.errors.code && formik.touched.code ? formik.errors.code : null}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="outlined-basic"
                                label="Price"
                                variant="outlined"
                                name="price"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.price}
                                fullWidth
                                disabled={loadingBoolean}
                                onBlur={formik.handleBlur}
                                error={formik.touched.price && Boolean(formik.errors.price)}
                                helperText={formik.errors.price && formik.touched.price ? formik.errors.price : null}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Quality Class</InputLabel>
                                <Select
                                    id="demo-simple-select"
                                    labelId="demo-simple-select-label"
                                    label="Quality Class"
                                    name="qualityClass"
                                    defaultValue=""
                                    disabled={loadingBoolean}
                                    value={formik.values.qualityClass}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.qualityClass && Boolean(formik.errors.qualityClass)}
                                    helperText={formik.touched.qualityClass && formik.errors.qualityClass}
                                >
                                    <MenuItem value="A">A</MenuItem>
                                    <MenuItem value="B">B</MenuItem>
                                    <MenuItem value="C">C</MenuItem>
                                </Select>
                                <Stack color={"#d32f2f"} fontSize="12px" ml={1.8}>
                                    {formik.errors.qualityClass && formik.touched.qualityClass ? formik.errors.qualityClass : null}
                                </Stack>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <DialogActions>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    loading={updateStatus === "pending" ? true : false}
                                >
                                    Save
                                </LoadingButton>
                                <Button
                                    variant="outlined"
                                    size="medium"
                                    onClick={handleCloseModal}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </ModalCommon>
    )
}

export default EditVariant