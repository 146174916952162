import React from "react";
import { Route, Redirect } from "react-router-dom";
import useAuth from "./redux/Login/selector";

// handle the private routes
function PrivateRoute({ component: Component, ...rest }) {
  const { loginStatus, loginToken } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        loginToken && (loginStatus === "success") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
export default PrivateRoute;
