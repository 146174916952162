import { useDispatch, useSelector } from "react-redux";
import {
  createProduct,
  deleteProduct,
  editProduct,
  editProductVariant,
  fetchAllAvailableProduct,
  fetchAllProducts,
  fetchAllSoldProduct,
  getProduct,
  resetProduct,
} from "./productSlice";

const useProducts = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.productData);
  const soldProduct = useSelector((state) => state.product.soldProducts);
  const availableProduct = useSelector((state) => state.product.availableProducts);
  const product = useSelector((state) => state.product.productOne);
  const status = useSelector((state) => state.product.status);
  const productStatus = useSelector((state) => state.product.productStatus);
  const createStatus = useSelector((state) => state.product.createStatus);
  const updateStatus = useSelector((state) => state.product.updateStatus);
  const removeStatus = useSelector((state) => state.product.removeStatus);
  const soldStatus = useSelector((state) => state.product.soldProductStatus);
  const availableStatus = useSelector((state) => state.product.availableProductStatus);

  const fetchAvailableProducts = (data) => dispatch(fetchAllAvailableProduct(data));
  const fetchSoldProducts = (data) => dispatch(fetchAllSoldProduct(data));
  const fetchProducts = (data) => dispatch(fetchAllProducts(data));
  const fetchProduct = (data) => dispatch(getProduct(data));
  const create = (data) => dispatch(createProduct(data));
  const update = (data) => dispatch(editProduct(data));
  const remove = (data) => dispatch(deleteProduct(data));
  const updateVariant = (data) => dispatch(editProductVariant(data));
  const reset = () => dispatch(resetProduct())
    return {
    availableProduct,
    soldProduct,
    products,
    product,
    status,
    productStatus,
    createStatus,
    updateStatus,
    removeStatus,
    availableStatus,
    soldStatus,
    fetchAvailableProducts,
    fetchSoldProducts,
    fetchProducts,
    fetchProduct,
    create,
    update,
    remove,
    updateVariant,
    reset,
  };
};
export default useProducts;
