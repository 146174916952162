import React, { useEffect, useState } from "react";
import { Avatar, Button, Divider, Grid, Tooltip } from "@mui/material";
import MainContainer from "../../common/MainContainer";
import { Stack } from "@mui/system";
import Searchbar from "../../common/Searchbar";
import AddIcon from "@mui/icons-material/Add";
import useSellers from "../../../redux/Seller/selector";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from "../../common/DataTable";
import AddSeller from "./add";
import ConfirmationModal from "../../common/ConfirmationModal";

export default function Seller() {
  const { sellers, status, removeStatus, fetchSellers, fetchSeller, remove } = useSellers()
  const [openModal, setOpenModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [deletedId, setDeletedId] = useState();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenConfirmationModal = () => {
    setOpenModalConfirmation(true)
  }
  const handleCloseConfirmationModal = () => {
    setOpenModalConfirmation(false)
  }

  const handleEditSeller = (value) => {
    fetchSeller(value);
    handleOpenModal();
    setIsEditable(true);
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      const res = sellers.filter((item) => item && item).filter((item) => {
        const inputTxt = (item.name + item.email + item.companyName).toLowerCase().trim().replace(/ /g, "")
        const text = (e.target.value).toLowerCase().trim().replace(/ /g, "")
        return inputTxt.includes(text)
      })
      setFilteredData(res)
    }
    else {
      setFilteredData(sellers.filter((item) => item && item))
    }
  }

  const handleDeleteSeller = (id) => {
    handleOpenConfirmationModal();
    setDeletedId(id)
  }

  const dispatchDelete = (id) => {
    remove({ sellerId: id });
  }

  useEffect(() => {
    if (status === "idle") {
      fetchSellers();
    }
  }, [fetchSellers, status]);

  useEffect(() => {
    setFilteredData(sellers.filter((item) => item && item))
  }, [sellers])

  const columns = [
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "companyName", label: "Company Name" },
    { id: "role", label: "Role" },
    {
      id: "_id",
      label: "Actions",
      jsx: (value) => (
        <Stack direction="row" spacing={1}>
          <Tooltip title="Edit">
            <Avatar
              sx={{
                background: "#4267B2",
                width: 35,
                height: 35,
                cursor: "pointer",
              }}
            >
              <EditIcon
                onClick={() => handleEditSeller(value)}
                fontSize="small"
              />
            </Avatar>
          </Tooltip>
          <Tooltip title="Remove" onClick={() => handleDeleteSeller(value)}>
            <Avatar sx={{ background: "#ba000d", width: 35, height: 35, cursor: "pointer" }}>
              <DeleteIcon fontSize="small" />
            </Avatar>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <MainContainer title={"Seller"}>
      <Stack direction="row" justifyContent="space-between" pb={3}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Searchbar handleSearch={handleSearch} />
        </Grid>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenModal}
        >
          Add Seller
        </Button>
      </Stack>
      <Divider />

      <DataTable
        columns={columns}
        rows={filteredData?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) ?? sellers.filter((item) => item && item)}
        loading={status === "pending"}
      />

      <AddSeller
        openModal={openModal}
        setOpenModal={setOpenModal}
        setIsEditable={setIsEditable}
        isEditable={isEditable}
      />

      <ConfirmationModal openModal={openModalConfirmation} removeStatus={removeStatus} handleClose={handleCloseConfirmationModal} id={deletedId} handleOk={dispatchDelete} content="Are you sure you want to Delete?" />
    </MainContainer>
  );
}
