import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiGet, ApiPost } from "../../component/api";

export const fetchAllSellers = createAsyncThunk(
  "seller/fetchAllSellers",
  async (_, thunkAPI) => {
    try {
      const result = await ApiGet("seller/all");
      if (result) {
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchSingleSeller = createAsyncThunk(
  "seller/getSeller",
  async (id, thunkAPI) => {
    try {
      const result = await ApiGet(`seller/${id}`);
      if (result) {
        return thunkAPI.fulfillWithValue(result.data[0]);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createSeller = createAsyncThunk(
  "seller/createSeller",
  async (sellerData, thunkAPI) => {
    try {
      const result = await ApiPost("seller/create", sellerData);
      if (result) {
        toast.success(result.message, { autoClose: 3000 });
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateSeller = createAsyncThunk(
  "seller/editSeller",
  async (sellerData, thunkAPI) => {
    try {
      const result = await ApiPost("seller/update", sellerData);
      if (result) {
        toast.success(result.message, { autoClose: 3000 });
        return thunkAPI.fulfillWithValue(result.data);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const removeSeller = createAsyncThunk(
  "seller/deleteSeller",
  async (id, thunkAPI) => {
    try {
      const result = await ApiPost("seller/delete", id);
      if (result) {
        toast.success(result.message, { autoClose: 3000 });
        return thunkAPI.fulfillWithValue(id);
      }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sellerSlice = createSlice({
  name: "seller",
  initialState: {
    status: "idle",
    createStatus: "idle",
    updateStatus: "idle",
    removeStatus: "idle",
    sellers: [],
    seller: []
  },
  reducers: {
    resetSeller(state) {
      state.updateStatus = "idle";
      state.removeStatus = "idle";
      state.createStatus = "idle";
      state.seller = {};
    },
  },
  extraReducers: {
    [fetchAllSellers.pending]: (state) => {
      state.status = "pending";
    },
    [fetchAllSellers.fulfilled]: (state, action) => {
      state.status = "success";
      state.sellers = action.payload;
      state.error = "";
    },
    [fetchAllSellers.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },

    [fetchSingleSeller.pending]: (state) => {
      state.status = "pending";
    },
    [fetchSingleSeller.fulfilled]: (state, action) => {
      state.status = "success";
      state.seller = action.payload;
    },
    [fetchSingleSeller.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },

    [createSeller.pending]: (state) => {
      state.createStatus = "pending";
    },
    [createSeller.fulfilled]: (state, action) => {
      state.createStatus = "success";
      state.sellers = [...state.sellers, action.payload];
    },
    [createSeller.rejected]: (state, action) => {
      state.createStatus = "failed";
      state.error = action.payload;
    },

    [updateSeller.pending]: (state) => {
      state.updateStatus = "pending";
    },
    [updateSeller.fulfilled]: (state, action) => {
      state.updateStatus = "success";
      const findIndex = state.sellers?.findIndex(
        (seller) => seller._id === action.payload._id
      );
      if (findIndex !== -1) {
        state.sellers[findIndex] = action.payload;
      }
    },
    [updateSeller.rejected]: (state, action) => {
      state.updateStatus = "failed";
      state.error = action.payload;
    },

    [removeSeller.pending]: (state) => {
      state.removeStatus = "pending";
    },
    [removeSeller.fulfilled]: (state, action) => {
      state.removeStatus = "success";
      state.sellers = state.sellers.filter(
        (seller) => seller._id !== action.payload.sellerId
      );
    },
    [removeSeller.rejected]: (state, action) => {
      state.removeStatus = "failed";
      state.error = action.payload;
    },
  },
});

export const { resetSeller } = sellerSlice.actions;

export default sellerSlice.reducer;